import { faCopy, faSave, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styles from "../../styles/WatchList.module.css";

function CompareList() {
  let navigate = useNavigate();
  const handleBack = () => {
    navigate("/");
  };
  const [Loading, setLoading] = useState(true)
  const [COID, setCOID] = useState(false)
  const [DataAt, setDataAt] = useState(false)
  const [GettingData, setGettingData] = useState(false)

  const makeRequest = (coid) => {
    axios.get(`https://sphoortitoolscatalogapi20211217232946.azurewebsites.net/tools/api/v1/catalogs/products/comparision-list?correlationid=${coid}`)
      .then((res) => {
        console.log(res.data.data)
        setDataAt(res.data.data)
        setLoading(false)
      }
      )
  }

  useEffect(() => {
    if (localStorage.getItem("compareItems")) {
      if (!COID && !localStorage.getItem("COID")) {
        const postThis = {
          "products": JSON.parse(localStorage.getItem("compareItems")).join()
        }
        axios.post("https://sphoortitoolscatalogapi20211217232946.azurewebsites.net/tools/api/v1/catalogs/products/comparision-list", postThis)
          .then((res) => {
            setCOID(res.data.data.corelationId)
            localStorage.setItem("COID", res.data.data.corelationId)
            // window.location.reload()
          })
      }
      else if (COID) {
        const postThis = {
          "products": JSON.parse(localStorage.getItem("compareItems")).join(),
          "corrletaionId": COID
        }
        setGettingData(true)
        axios.post(`https://sphoortitoolscatalogapi20211217232946.azurewebsites.net/tools/api/v1/catalogs/products/comparision-list`, postThis)
          .then((res) => {
            console.log(res.data.data)
            setGettingData(false)
            makeRequest(COID)
          })
      }
      else if (localStorage.getItem("COID")) {
        const postThis = {
          "products": JSON.parse(localStorage.getItem("compareItems")).join(),
          "corrletaionId": localStorage.getItem("COID")
        }
        setGettingData(true)
        axios.post(`https://sphoortitoolscatalogapi20211217232946.azurewebsites.net/tools/api/v1/catalogs/products/comparision-list`, postThis)
          .then((res) => {
            console.log(res.data.data)
            setGettingData(false)
            makeRequest(localStorage.getItem("COID"))
          })
      }
    }
    else {
      setLoading(false)
    }
  }, [])

  // useEffect(() => {
  //   if (COID) {
  //     !GettingData &&

  //   }
  //   else if (localStorage.getItem("COID")) {
  //     !GettingData &&
  //       axios.get(`https://sphoortitoolscatalogapi20211217232946.azurewebsites.net/tools/api/v1/catalogs/products/comparision-list?correlationid=${localStorage.getItem("COID")}`)
  //         .then((res) => {
  //           console.log(res.data.data)
  //           setDataAt(res.data.data)
  //           setLoading(false)
  //         }
  //         )
  //   }
  // }, [])

  return (
    <div className={styles.WatchList}>
      <div className={styles.TopDiv}>
        <h2>Compare List</h2>
      </div>
      <div className={styles.MainContainer} style={{ width: "90%", display: "flex", flexDirection: "column" }}>
        <div className={styles.MainHeader}>
          <button onClick={() => handleBack()}>&lt; Back</button>
          <h3>PRODUCTS</h3>
          <button
            onClick={() => {
              localStorage.removeItem("compareItems");
              window.location.reload();
            }}
          >
            Clear All
          </button>
        </div>
        {DataAt ? (
          <table>
            <thead>
              <tr>
                <th>NAME</th>
                {DataAt && DataAt.products.map((el) => (<th><div>{el.articleId}
                  <FontAwesomeIcon
                    icon={faTrashAlt}
                    style={{ color: "red" }}
                    onClick={() => {
                      JSON.parse(localStorage.getItem("compareItems")).length >
                        1
                        ? localStorage.setItem(
                          "compareItems",
                          JSON.stringify(
                            JSON.parse(
                              localStorage.getItem("compareItems")
                            ).filter(
                              (elm) => elm !== el.code
                            )
                          )
                        )
                        : localStorage.removeItem("compareItems");
                      window.location.reload();
                    }}
                  />
                </div>
                </th>))}
              </tr>
            </thead>
            <tbody>
              {DataAt.comparisionList.map((el) => (
                <tr>
                  <td>{el.attribute}</td>
                  {el.values.map((element) => (
                    <td>{element === "NULL" ? "-" : element}</td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        ) : Loading ? (
          <div className={styles.ToolDataContainer} style={{ padding: "40px" }}>
            <h1>Loading</h1>
          </div>
        ) :
          (
            <div className={styles.ToolDataContainer} style={{ padding: "40px" }}>
              <h1>Add more than one item in compare list</h1>
            </div>
          )}
      </div>
    </div>
  );
}

export default CompareList;
