import React from "react";
import { useNavigate } from "react-router-dom";
import styles from "../../styles/SelectManufacturer.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHandPointRight } from "@fortawesome/free-solid-svg-icons";
import {
  faBook,
  faTimes,
  faQuestion,
  faListUl,
  faCheck,
} from "@fortawesome/free-solid-svg-icons";

function PageFlowMachineTool({
  Step,
  setStep,
  Manu,
  Type,
  Spec,
  Category,
  Tool,
}) {
  const navigate = useNavigate();
  const handleBack = () => {
    navigate("/");
    setStep(1);
    localStorage.removeItem('selectToolFilters')
    // localStorage.clear();
  };
  const handleClickManu = () => {
    navigate("/machine-tool");
    setStep(1);
    localStorage.removeItem("ManuName");
    localStorage.removeItem("ManuCode");
    localStorage.removeItem('selectCatFilters')
    localStorage.removeItem("Step");
    localStorage.removeItem('selectToolFilters')
    
  };
  const handleClickType = () => {
    navigate("/machine-tool/machine-type");
    setStep(2);
    localStorage.removeItem("TypeName");
    localStorage.removeItem("TypeCode");
    localStorage.setItem("Step", 2);
    localStorage.removeItem('selectCatFilters')
    localStorage.removeItem('selectToolFilters')
  };
  // const handleClickSpec = () => {
  //   navigate("/machine-tool/spec");
  //   setStep(3);
  // };
  const handleClickCategory = () => {
    setStep(3);
    navigate("/machine-tool/select-category");
    localStorage.removeItem("CategoryName");
    localStorage.removeItem("CategoryCode");
    localStorage.setItem("Step", 3);
    localStorage.removeItem('selectToolFilters')
  };
  const handleClickTool = () => {
    navigate("/machine-tool/select-tool");
    setStep(4);
    localStorage.removeItem("ToolName");
    // localStorage.removeItem("TypeCode");
    localStorage.setItem("Step", 4);
  
  };

  // console.log(Step);
  return (
    <div className={styles.PageFlowContainer}  style={{height:"360px"}}>
      <div className={styles.ProgressBar}>
        <div
          className={styles.Progress}
          style={{
            height:
              Step === 1
                ? "20%"
                : Step === 2
                ? "40%"
                : Step === 3
                ? "60%"
                : Step === 4
                ? "80%"
                : "100%",
          }}
        ></div>
      </div>
      <div className={styles.PageFlowElement} onClick={handleBack}>
        <p style={{ marginBottom: "0px" }}>Back to main catalog</p>
        <FontAwesomeIcon
            style={{ color: "#275207"}}
            icon={faHandPointRight}
            className={styles.fontChange}
          />
        <div
          className={styles.IconContainer}
          style={{
            backgroundColor: "#275207",
            height: "65px",
            width: "65px",
            marginBottom: "0px",
            marginTop: "0px",
          }}
        >
          <FontAwesomeIcon
            icon={faBook}
            className={styles.ElementIcon}
            style={{ height: "65px", width: "35px", fontSize: "30px" }}
          />
        </div>
        <p></p>
      </div>
      <div className={styles.PageFlowElement}>
        
        {Step <= 1 ? (
          <>
           <FontAwesomeIcon
            style={{ color: "#275207"}}
            icon={faHandPointRight}
            className={styles.fontChange}
          />
           <p>Select Machine manufacturer</p>
           <FontAwesomeIcon
            style={{ color: "#275207"}}
            icon={faHandPointRight}
            className={styles.fontChange}
          />
            <div
              className={styles.IconContainer}
              style={{ backgroundColor: "#c70b0b" }}
            >
              <FontAwesomeIcon
                icon={faQuestion}
                className={styles.ElementIcon}
              />
            </div>
            <p ></p>
          </>
        ) : (
          <>
           <FontAwesomeIcon
            style={{ color: "#275207"}}
            icon={faHandPointRight}
            className={styles.fontChange}
          />
          
          <p  onClick={handleClickManu}>Select machine manufacturer</p>
            <div
              className={styles.IconContainer}
              onClick={handleClickManu}
              style={{ backgroundColor: "#275207" }}
            >
              <FontAwesomeIcon icon={faCheck} className={styles.ElementIcon}/>
            </div>
            <p className={styles.pageFlowDescription}>
              {Manu ? Manu.name : localStorage.getItem("ManuName")}
            </p>
          </>
        )}
      </div>
      <div className={styles.PageFlowElement}>
        {Step === 2 ? (
          <>
          <p >Select machine model</p>
          <FontAwesomeIcon
            style={{ color: "#275207"}}
            icon={faHandPointRight}
            className={styles.fontChange}
          />
          
            <div
              className={styles.IconContainer}
              style={{ backgroundColor: "#c70b0b" }}
            >
              <FontAwesomeIcon
                icon={faQuestion}
                className={styles.ElementIcon}
              />
            </div>
                <p ></p>
          
          </>
        ) : Step > 2 ? (
          <>
           <FontAwesomeIcon
            style={{ color: "#275207"}}
            icon={faHandPointRight}
            className={styles.fontChange}
          />
           <p onClick={handleClickType} >Select machine model</p>
           
            <div
              className={styles.IconContainer}
              style={{ backgroundColor: "#275207" }}
              onClick={handleClickType}
            >
              <FontAwesomeIcon icon={faCheck} className={styles.ElementIcon} />
            </div>
            <p className={styles.pageFlowDescription}>
              {Type ? Type.name.substring(0,16) : localStorage.getItem("TypeName")}..
            </p>
          </>
        ) : (
          <>
           <FontAwesomeIcon
            style={{ color: "#275207"}}
            icon={faHandPointRight}
            className={styles.fontChange}
          />
          <p >Select machine model</p>
            <div className={styles.IconContainer}>
              <FontAwesomeIcon icon={faTimes} className={styles.ElementIcon} />
            </div>
            <p></p>
          </>
        )}
      </div>
      {/* <div className={styles.PageFlowElement}>
        <p>Select specification</p>
        {Step === 3 ? (
          <div
            className={styles.IconContainer}
            style={{ backgroundColor: "#c70b0b" }}
          >
            <FontAwesomeIcon icon={faQuestion} className={styles.ElementIcon} />
          </div>
        ) : Step > 3 ? (
          <>
            <div
              className={styles.IconContainer}
              style={{ backgroundColor: "#a4d62c" }}
              onClick={handleClickSpec}
            >
              <FontAwesomeIcon icon={faCheck} className={styles.ElementIcon} />
            </div>
            <p style={{ height: "0px" }}>{Spec}</p>
          </>
        ) : (
          <div className={styles.IconContainer}>
            <FontAwesomeIcon icon={faTimes} className={styles.ElementIcon} />
          </div>
        )}
      </div> */}
      <div className={styles.PageFlowElement}>
        {Step === 3 ? (
          
          <>
           <FontAwesomeIcon
            style={{ color: "#275207"}}
            icon={faHandPointRight}
            className={styles.fontChange}
          />
           <p>Select Tool Holder Type</p>
            <div
              className={styles.IconContainer}
              style={{ backgroundColor: "#c70b0b" }}
            >
              <FontAwesomeIcon
                icon={faQuestion}
                className={styles.ElementIcon}
              />
            </div>
            <p></p>
          </>
        ) : Step > 3 ? (
          <>
           <FontAwesomeIcon
            style={{ color: "#275207"}}
            icon={faHandPointRight}
            className={styles.fontChange}
          />
           <p onClick={handleClickCategory}>Select Product Family</p>
            <div
              className={styles.IconContainer}
              style={{ backgroundColor: "#275207" }}
              onClick={handleClickCategory}
            >
              <FontAwesomeIcon icon={faCheck} className={styles.ElementIcon} />
            </div>
            <p className={styles.pageFlowDescription}>
              {Category ? Category.name.substring(0,17) : localStorage.getItem("CategoryName")}..
            </p>
          </>
        ) : (
          <>
           <FontAwesomeIcon
            style={{ color: "#275207"}}
            icon={faHandPointRight}
            className={styles.fontChange}
          />
          <p onClick={handleClickCategory}>Select Tool Holder Type</p>
            <div className={styles.IconContainer}>
              <FontAwesomeIcon icon={faTimes} className={styles.ElementIcon} />
            </div>
            <p></p>
          </>
        )}
      </div>
      <div className={styles.PageFlowElement}>
        {Step === 4 ? (
          <>
           <FontAwesomeIcon
            style={{ color: "#275207"}}
            icon={faHandPointRight}
            className={styles.fontChange}
          />
          <p>Select Tool</p>
            <div
              className={styles.IconContainer}
              style={{ backgroundColor: "#c70b0b" }}
            >
              <FontAwesomeIcon
                icon={faQuestion}
                className={styles.ElementIcon}
              />
            </div>
            <p></p>
          </>
        ) : Step > 4 ? (
          <>
           <FontAwesomeIcon
            style={{ color: "#275207"}}
            icon={faHandPointRight}
            className={styles.fontChange}
          />
          <p  onClick={handleClickTool}>Select Tool</p>
            <div
              className={styles.IconContainer}
              style={{ backgroundColor: "#275207" }}
              onClick={handleClickTool}
            >
              <FontAwesomeIcon icon={faCheck} className={styles.ElementIcon} />
            </div>
            <p className={styles.pageFlowDescription}>
              {Tool ? Tool.displayName.substring(0,14) : localStorage.getItem("ToolName")}..
            </p>
          </>
        ) : (
          <>
           <FontAwesomeIcon
            style={{ color: "#275207"}}
            icon={faHandPointRight}
            className={styles.fontChange}
          />
           <p>Select Tool Holder</p>
            {" "}
            <div className={styles.IconContainer}>
              <FontAwesomeIcon icon={faTimes} className={styles.ElementIcon} />
            </div>
            <p></p>
          </>
        )}
      </div>
      <div className={styles.PageFlowElement}>
        <p style={{ marginBottom: "0px" }}>Tool details</p>
        {Step === 5 ? (
          <>
            <div
              className={styles.IconContainer}
              style={{
                height: "65px",
                width: "65px",
                marginBottom: "0px",
                marginTop: "0px",
                backgroundColor: "#275207",
              }}
            >
              <FontAwesomeIcon
                icon={faListUl}
                className={styles.ElementIcon}
                style={{ height: "65px", width: "35px", fontSize: "30px" }}
              />
            </div>
            <p></p>
          </>
        ) : (
          <>
            <div
              className={styles.IconContainer}
              style={{
                height: "65px",
                width: "65px",
                marginBottom: "0px",
                marginTop: "0px",
              }}
            >
              <FontAwesomeIcon
                icon={faListUl}
                className={styles.ElementIcon}
                style={{ height: "65px", width: "35px", fontSize: "30px" }}
              />
            </div>
            <p></p>
          </>
        )}
      </div>
    </div>
  );
}

export default PageFlowMachineTool;
