import React from "react";
import { useNavigate } from "react-router-dom";
import styles from "../../styles/SelectManufacturer.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBook,
  faTimes,
  faHandPointRight ,
  faQuestion,
  faListUl,
  faCheck,
} from "@fortawesome/free-solid-svg-icons";

function PageFlowArticle({
  Step,
  setStep,
  Manu,
  Type,
  Spec,
  Category,
  Tool,
  Article,
}) {
  const navigate = useNavigate();
  const handleBack = () => {
    navigate("/");
    setStep(1);
  };
  const handleArticle = () => {
    navigate("/article-number");
    setStep(1);
  };
  const handleClickType = () => {
    navigate("/machine-tool/machine-type");
    setStep(2);
  };
  const handleClickSpec = () => {
    navigate("/machine-tool/spec");
    setStep(3);
  };
  const handleClickCategory = () => {
    navigate("/machine-tool/select-category");
    setStep(4);
  };
  const handleClickTool = () => {
    navigate("/article-number/select-tool");
    setStep(2);
  };

  // console.log(Step);
  return (
    <div className={styles.PageFlowContainer}>
      <div className={styles.ProgressBar} style={{height:"60%", marginTop:"10px"}}>
        <div
          className={styles.Progress}
          style={{
            height: Step === 1 ? "35%" : Step === 2 ? "60%" : "100%",
          }}
        ></div>
      </div>
      <div className={styles.PageFlowElement} onClick={handleBack}>
      <FontAwesomeIcon
            style={{ color: "#275207"}}
            icon={faHandPointRight}
            className={styles.fontChange}
          /> 
        <p>Back to main catalog</p>
        <div
          className={styles.IconContainer}
          style={{
            backgroundColor: "#275207",
            height: "65px",
            width: "65px",
            marginBottom: "0px",
            marginTop: "0px",
          }}
        >
          <FontAwesomeIcon
            icon={faBook}
            className={styles.ElementIcon}
            style={{ height: "65px", width: "35px", fontSize: "30px" }}
          />
        </div>
        <p></p>
      </div>
      <div className={styles.PageFlowElement}>
      <FontAwesomeIcon
            style={{ color: "#275207"}}
            icon={faHandPointRight}
            className={styles.fontChange}
          /> 
        <p>Write article number</p>
        {Step <= 1 ? (
          <>
            <div
              className={styles.IconContainer}
              style={{ backgroundColor: "#c70b0b" }}
            >
              <FontAwesomeIcon
                icon={faQuestion}
                className={styles.ElementIcon}
              />
            </div>
            <p></p>
          </>
        ) : (
          <>
            <div
              className={styles.IconContainer}
              onClick={handleArticle}
              style={{ backgroundColor: "#275207" }}
            >
              <FontAwesomeIcon icon={faCheck} className={styles.ElementIcon} />
            </div>
            <p style={{ height: "0px" }}>{Article? Article : localStorage.getItem("Article")}</p>
          </>
        )}
      </div>
      {/* <div className={styles.PageFlowElement}>
        <p>Select Tool</p>
        {Step === 2 ? (
          <>
            {" "}
            <div
              className={styles.IconContainer}
              style={{ backgroundColor: "#c70b0b" }}
            >
              <FontAwesomeIcon
                icon={faQuestion}
                className={styles.ElementIcon}
              />
            </div>
            <p></p>
          </>
        ) : Step > 2 ? (
          <>
            <div
              className={styles.IconContainer}
              style={{ backgroundColor: "#67a92c" }}
              onClick={handleClickTool}
            >
              <FontAwesomeIcon icon={faCheck} className={styles.ElementIcon} />
            </div>
            <p style={{ height: "0px" }}>{localStorage.getItem("ToolName")}</p>
          </>
        ) : (
          <>
            <div className={styles.IconContainer}>
              <FontAwesomeIcon icon={faTimes} className={styles.ElementIcon} />
            </div>
            <p></p>
          </>
        )}
      </div> */}
      <div className={styles.PageFlowElement}>
        <p>Tool details</p>
        {Step === 3 ? (
          <>
            <div
              className={styles.IconContainer}
              style={{
                height: "65px",
                width: "65px",
                marginBottom: "0px",
                marginTop: "0px",
                backgroundColor: "#275207",
              }}
            >
              <FontAwesomeIcon
                icon={faListUl}
                className={styles.ElementIcon}
                style={{ height: "65px", width: "35px", fontSize: "30px" }}
              />
            </div>
            <p></p>
          </>
        ) : (
          <>
            <div
              className={styles.IconContainer}
              style={{
                height: "65px",
                width: "65px",
                marginBottom: "0px",
                marginTop: "0px",
              }}
            >
              <FontAwesomeIcon
                icon={faListUl}
                className={styles.ElementIcon}
                style={{ height: "65px", width: "35px", fontSize: "30px" }}
              />
            </div>
            <p></p>
          </>
        )}
      </div>
    </div>
  );
}

export default PageFlowArticle;
