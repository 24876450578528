import React, { useEffect, useState } from "react";
import styles from "../../styles/SelectManufacturer.module.css";
import { useNavigate } from "react-router-dom";
import PageFlowMachineTool from "./PageFlowMachineTool";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCopy,
  faHandPointRight,
  faShoppingCart,
  faFilter,
} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";

function SelectTool({
  Step,
  setStep,
  Manu,
  Type,
  Spec,
  Category,
  setTool,
  setToolDetail,
}) {
  let navigate = useNavigate();
  const [Data, setData] = useState([]);
  const [Loading, setLoading] = useState(true);
  const [Filters, setFilters] = useState(false);
  const [PostData, setPostData] = useState({
    modelCode: `${Type ? Type.code : localStorage.getItem("TypeCode")}`,
    categoryCode: Category
      ? Category.code
      : localStorage.getItem("CategoryCode"),
    filters: [],
  });
  const postThis = localStorage.getItem("selectToolFilters")
    ? JSON.parse(localStorage.getItem("selectToolFilters")).categoryCode ===
      localStorage.getItem("CategoryCode")
      ? JSON.parse(localStorage.getItem("selectToolFilters"))
      : PostData
    : PostData;
  const handleFilter = (filter, category) => {
    localStorage.setItem("Step", 4);
    const isSameElement = (element) => element.category === category;
    const isStringElement = (element) => element.category === "string";
    const isSameFilter = (element) => element === filter;
    // setLoading(true);
    // setData(null);
    postThis.filters.some((element) => element.category === "string") &&
      postThis.filters.splice(postThis.filters.findIndex(isStringElement), 1);
    const object = {
      category: `${category}`,
      filters: [`${filter}`],
    };
    postThis.filters.some((element) => element.category === category)
      ? postThis.filters[
        postThis.filters.findIndex(isSameElement)
      ].filters.some((element) => element === filter)
        ? postThis.filters[
          postThis.filters.findIndex(isSameElement)
        ].filters.splice(
          postThis.filters[
            postThis.filters.findIndex(isSameElement)
          ].filters.findIndex(isSameFilter),
          1
        )
        : postThis.filters[
          postThis.filters.findIndex(isSameElement)
        ].filters.push(filter)
      : postThis.filters.push(object);

    for (let i = 0; i < postThis.filters.length; i++) {
      if (postThis.filters[i].filters.length === 0) {
        postThis.filters.splice(i, 1);
      }
    }

    setPostData(postThis);
    localStorage.setItem("selectToolFilters", JSON.stringify(postThis));
    // axios
    //   .post(
    //     "https://sphoortitoolscatalogapi20211217232946.azurewebsites.net/tools/api/v1/catalogs/tool-models/tool-categories/tool-products-filters",
    //     postThis
    //   )
    //   .then((res) => {
        // setData(res.data.data.products);
        // console.log(res)
        // setFilters(res.data.data.productFilters)
        // // setLoading(false);
        window.location.reload();
      // });
  };
  const handleSelectTool = (tool) => {
    setStep(5);
    navigate(`/turrets/tool-details`);
    setTool(tool);
    localStorage.setItem("ToolName", tool.displayName);
    localStorage.setItem("ToolFeature", tool.feature.value);
    localStorage.setItem("ToolMachine", JSON.stringify(tool.machineReferences));
    localStorage.setItem("ToolImage", tool.images.bigImageURL);
    localStorage.setItem("ToolDocs", JSON.stringify(tool.docs));
    localStorage.setItem("ToolMain", JSON.stringify(tool));
    localStorage.removeItem('selectCatFilters')
    // localStorage.setItem("CategoryName", element.name);
    localStorage.setItem("Step", 5);
    console.log(tool, "tool details");
    // setToolDetail(toolDetail);
  };
  // useEffect(() => {
  //   // console.log(Category);
  //   setStep(JSON.parse(localStorage.getItem("Step")));
  //   axios
  //     .get(
  //       `https://sphoortitoolscatalogapi20211217232946.azurewebsites.net/tools/api/v1/catalogs/tool-models/${Type ? Type.code : localStorage.getItem("TypeCode")
  //       }/categories/${Category ? Category.code : localStorage.getItem("CategoryCode")
  //       }/products/filters`
  //     )
  //     .then((res) => {
  //       setFilters(res.data.data);
  //     });
  //   // console.log(Data);
  // }, []);
  useEffect(() => {
    setStep(JSON.parse(localStorage.getItem("Step")));
    axios
    .post(
      "https://sphoortitoolscatalogapi20211217232946.azurewebsites.net/tools/api/v1/catalogs/tool-models/tool-categories/tool-products-filters",
      localStorage.getItem("selectToolFilters") ? JSON.parse(localStorage.getItem("selectToolFilters")):PostData
    )
    .then((res) => {
      setData(res.data.data.products);
      console.log(res)
      setFilters(res.data.data.productFilters)
      setLoading(false);
    });
  }, []);
  // const alphabetArray = "abcdefghijklmnopqrstuvwxyz".split("");
  // const Arr = ["Static tools", "Driven tools"];
  return (
    <div className={`${styles.HomePage} ${styles.MachineToolsFourth}`}>
      <div className={styles.PageFlowWrapper}>
        <div className={styles.TopDiv}>
          <h2>
            <b>Search Your Tool Holder</b>
          </h2>
        </div>
        <PageFlowMachineTool
          Step={Step}
          setStep={setStep}
          Manu={Manu}
          Type={Type}
          Spec={Spec}
          Category={Category}
        />
      </div>
      <div className={styles.PageContentWrapper} style={{ marginTop: "35px" }}>
        <h3 className={styles.StickyHeading}>
          {Category ? Category.name : localStorage.getItem("CategoryName")}
        </h3>
        <div className={styles.TopDiv}>
          <h2>
            <b>Select Tool Holder</b>
          </h2>
          {/* <p>
            Select the tool of your choice in order to display the tool details
            for it. Use the quick navigation to jump to the selected group in
            the list.
          </p> */}
        </div>
        <div className={styles.ToolDictionaryContainer}>
          <FontAwesomeIcon
            style={{ color: "#275207" }}
            icon={faHandPointRight}
            className={styles.ElementIcon}
          />
          <ul className={styles.ToolListContainer}>
            {Loading && <p>Loading...</p>}
            {!Loading &&
              Data.map((element) => {
                return (
                  <li>
                    <a href={`#${element.displayName}`}>
                      {element.displayName}
                    </a>
                  </li>
                );
              })}
          </ul>
        </div>
        {!Loading &&
          Data.map((element) => {
            return (
              <div
                className={styles.ToolDataContainer}
                onClick={() => handleSelectTool(element)}
              >
                <h1
                  className={styles.ToolDataTag}
                  id={`${element.displayName}`}
                >
                  {element.displayName}
                </h1>
                <div className={styles.ToolHeading}>
                  <h1>{element.articleId}</h1>
                  {/* <button>
                  <FontAwesomeIcon icon={faCopy} /> Add to comparison
                </button> */}
                </div>
                <div className={styles.ToolDetails}>
                  <div>
                    <h2>
                      <FontAwesomeIcon
                        style={{ color: "#275207", width: "20px" }}
                        icon={faHandPointRight}
                        className={styles.ElementIcon}
                      />{" "}
                      Click For Tool Holder Details
                    </h2>
                    <p>{element.displayName}</p>
                  </div>
                  <img
                    alt="product"
                    style={{ width: "20%" }}
                    src={element.images.bigImageURL}
                  />
                  {/* <ul>
                  <li>
                    <span>X: 0</span>
                  </li>
                  <li>
                    <span>Mnr: S03513</span>
                  </li>
                </ul> */}
                </div>
              </div>
            );
          })}
      </div>
      <div className={styles.FilterWrapper}>
        <div className={styles.TopDiv} style={{ display: "flex" }}>
          <h2>
            <b>Filter By Parameter</b>
          </h2>
          <FontAwesomeIcon
            style={{ color: "#275207" }}
            icon={faFilter}
            className={styles.ElementIcon}
          />
          {/* <img src="https://ik.imagekit.io/0rsvz9vomoe/catalog_home/filter-icon-25207_u7eEsrP4n.png?ik-sdk-version=javascript-1.4.3updatedAt=1645774007729" style={{width:"50px" ,height:"50px",marginTop:"5px"}}></img> */}
        </div>
        <div
          className={styles.SpecDictionaryContainer}
          // style={{ marginTop: "40px" }}
        >
          <div className={styles.SpecContainer}>
            {Filters &&
              Filters.map((element) => {
                return (
                  <>
                    <div
                      className={styles.SpecHeading}
                      style={{ fontSize: "8px" }}
                    >
                      <h1>{element.category}</h1>
                    </div>
                    {/* {console.log(element,'check123')} */}
                    <div className={styles.FilterListContainer}>
                      {element.filters.map((filt) => {
                        return (
                          <div>
                            <input
                              type="checkbox"
                              onClick={() =>
                                handleFilter(filt, element.category)
                              }
                              defaultChecked={
                                localStorage.getItem("selectToolFilters") &&
                                  JSON.parse(
                                    localStorage.getItem("selectToolFilters")
                                  ).categoryCode ===
                                  localStorage.getItem("CategoryCode") &&
                                  JSON.parse(
                                    localStorage.getItem("selectToolFilters")
                                  ).filters.some(
                                    (el) => el.category === element.category
                                  )
                                  ? JSON.parse(
                                    localStorage.getItem("selectToolFilters")
                                  ).filters[
                                    JSON.parse(
                                      localStorage.getItem(
                                        "selectToolFilters"
                                      )
                                    ).filters.findIndex(
                                      (el) => el.category === element.category
                                    )
                                  ].filters.some((el) => el === filt)
                                  : false
                              }
                            ></input>
                            <label>{filt}</label>
                            {/* {localStorage. clear()}  */}
                          </div>
                        );
                      })}
                    </div>
                  </>
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default SelectTool;
