import React from "react";
import styles from "../../styles/SelectManufacturer.module.css";
import { useNavigate } from "react-router-dom";
import PageFlowArticle from "./PageFlowArticle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShoppingCart } from "@fortawesome/free-solid-svg-icons";

function SelectTool({
  Step,
  setStep,
  Manu,
  Type,
  Spec,
  Category,
  setTool,
  setToolDetail,
  Article,
}) {
  let navigate = useNavigate();
  const handleSelectTool = (tool, toolDetail) => {
    setStep(3);
    navigate(`/article-number/tool-details`);
    setTool(tool);
    setToolDetail(toolDetail);
  };
  // const alphabetArray = "abcdefghijklmnopqrstuvwxyz".split("");
  // const Arr = ["Static tools", "Driven tools"];
  return (
    <div className={styles.HomePage}>
      <div className={styles.PageFlowWrapper}>
        <div className={styles.TopDiv}>
          <h2>
            <b>Search Your Tool Holder</b>
          </h2>
        </div>
        <PageFlowArticle
          Step={Step}
          setStep={setStep}
          Manu={Manu}
          Type={Type}
          Spec={Spec}
          Category={Category}
          Article={Article}
        />
      </div>
      <div className={styles.PageContentWrapper}>
        <div className={styles.TopDiv}>
          <h2>
            <b>Select Toolx</b>
          </h2>
          <p>
            Select the tool of your choice in order to display the tool details
            for it. Use the quick navigation to jump to the selected group in
            the list.
          </p>
        </div>
        <div className={styles.ToolDictionaryContainer}>
          <ul className={styles.ToolListContainer}>
            <li>
              <a href="#shank dia. 10">Shank dia. 10</a>
            </li>
            <li>
              <a href="#shank dia. 12">Shank dia. 12</a>
            </li>
            <li>
              <a href="#shank dia. 16">Shank dia. 16</a>
            </li>
            <li>
              <a href="#shank dia. 20">Shank dia. 20</a>
            </li>
            <li>
              <a href="#shank dia. 25">Shank dia. 25</a>
            </li>
            <li>
              <a href="#shank dia. 32">Shank dia. 32</a>
            </li>
            <li>
              <a href="#shank dia. 40">Shank dia. 40</a>
            </li>
          </ul>
        </div>
        <div
          className={styles.ToolDataContainer}
          onClick={() => handleSelectTool("shank dia. 10", "19.V4ABS50L42")}
        >
          <h1 className={styles.ToolDataTag} id="shank dia. 10">
            Shank dia. 10
          </h1>
          <div className={styles.ToolHeading}>
            <h1>19.V4ABS50L42</h1>
            <button>
              <FontAwesomeIcon icon={faShoppingCart} /> Add to comparison
            </button>
          </div>
          <div className={styles.ToolDetails}>
            <h2>Tool data</h2>
            <p>Boring bar holder</p>
            <p>ABS 50 License Komet</p>
            <p>EWS Varia insert V4</p>
            <ul>
              <li>
                <span>X: 0</span>
              </li>
              <li>
                <span>Mnr: S03513</span>
              </li>
            </ul>
          </div>
        </div>
        <div
          className={styles.ToolDataContainer}
          onClick={() => handleSelectTool("shank dia. 12", "19.V4ABS50L42")}
        >
          <h1 className={styles.ToolDataTag} id="shank dia. 12">
            Shank dia. 12
          </h1>
          <div className={styles.ToolHeading}>
            <h1>19.V4ABS50L42</h1>
            <button>
              <FontAwesomeIcon icon={faShoppingCart} />
              Add to comparison
            </button>
          </div>
          <div className={styles.ToolDetails}>
            <h2>Tool data</h2>
            <p>Boring bar holder</p>
            <p>ABS 50 License Komet</p>
            <p>EWS Varia insert V4</p>
            <ul>
              <li>
                <span>X: 0</span>
              </li>
              <li>
                <span>Mnr: S03513</span>
              </li>
            </ul>
          </div>
        </div>
        <div className={styles.ToolDataContainer}>
          <h1 className={styles.ToolDataTag} id="shank dia. 16">
            Shank dia. 16
          </h1>
          <div className={styles.ToolHeading}>
            <h1>19.V4ABS50L42</h1>
            <button>
              <FontAwesomeIcon icon={faShoppingCart} /> Add to comparison
            </button>
          </div>
          <div className={styles.ToolDetails}>
            <h2>Tool data</h2>
            <p>Boring bar holder</p>
            <p>ABS 50 License Komet</p>
            <p>EWS Varia insert V4</p>
            <ul>
              <li>
                <span>X: 0</span>
              </li>
              <li>
                <span>Mnr: S03513</span>
              </li>
            </ul>
          </div>
        </div>
        <div className={styles.ToolDataContainer}>
          <h1 className={styles.ToolDataTag} id="shank dia. 20">
            Shank dia. 20
          </h1>
          <div className={styles.ToolHeading}>
            <h1>19.V4ABS50L42</h1>
            <button>
              <FontAwesomeIcon icon={faShoppingCart} /> Add to comparison
            </button>
          </div>
          <div className={styles.ToolDetails}>
            <h2>Tool data</h2>
            <p>Boring bar holder</p>
            <p>ABS 50 License Komet</p>
            <p>EWS Varia insert V4</p>
            <ul>
              <li>
                <span>X: 0</span>
              </li>
              <li>
                <span>Mnr: S03513</span>
              </li>
            </ul>
          </div>
        </div>
        <div className={styles.ToolDataContainer}>
          <h1 className={styles.ToolDataTag} id="shank dia. 25">
            Shank dia. 25
          </h1>
          <div className={styles.ToolHeading}>
            <h1>19.V4ABS50L42</h1>
            <button>
              <FontAwesomeIcon icon={faShoppingCart} /> Add to comparison
            </button>
          </div>
          <div className={styles.ToolDetails}>
            <h2>Tool data</h2>
            <p>Boring bar holder</p>
            <p>ABS 50 License Komet</p>
            <p>EWS Varia insert V4</p>
            <ul>
              <li>
                <span>X: 0</span>
              </li>
              <li>
                <span>Mnr: S03513</span>
              </li>
            </ul>
          </div>
        </div>
        <div className={styles.ToolDataContainer}>
          <h1 className={styles.ToolDataTag} id="shank dia. 32">
            Shank dia. 32
          </h1>
          <div className={styles.ToolHeading}>
            <h1>19.V4ABS50L42</h1>
            <button>
              <FontAwesomeIcon icon={faShoppingCart} /> Add to comparison
            </button>
          </div>
          <div className={styles.ToolDetails}>
            <h2>Tool data</h2>
            <p>Boring bar holder</p>
            <p>ABS 50 License Komet</p>
            <p>EWS Varia insert V4</p>
            <ul>
              <li>
                <span>X: 0</span>
              </li>
              <li>
                <span>Mnr: S03513</span>
              </li>
            </ul>
          </div>
        </div>
        <div className={styles.ToolDataContainer}>
          <h1 className={styles.ToolDataTag} id="shank dia. 40">
            Shank dia. 40
          </h1>
          <div className={styles.ToolHeading}>
            <h1>19.V4ABS50L42</h1>
            <button>
              <FontAwesomeIcon icon={faShoppingCart} /> Add to comparison
            </button>
          </div>
          <div className={styles.ToolDetails}>
            <h2>Tool data</h2>
            <p>Boring bar holder</p>
            <p>ABS 50 License Komet</p>
            <p>EWS Varia insert V4</p>
            <ul>
              <li>
                <span>X: 0</span>
              </li>
              <li>
                <span>Mnr: S03513</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className={styles.FilterWrapper}>
        <div className={styles.TopDiv}>
          <h2>
            <b>Restriction by characteristics</b>
          </h2>
        </div>
        <div
          className={styles.SpecDictionaryContainer}
          style={{ marginTop: "70px" }}
        >
          <div className={styles.SpecContainer}>
            <div className={styles.SpecHeading} style={{ fontSize: "8px" }}>
              <h1>Tool holder type</h1>
            </div>
            <div className={styles.FilterListContainer}>
              <div>
                <input type="checkbox"></input>
                <label>Driven</label>
              </div>
              <div>
                <input type="checkbox"></input>
                <label>Static</label>
              </div>
            </div>
            <div className={styles.SpecHeading} style={{ fontSize: "8px" }}>
              <h1>Tool Clamping</h1>
            </div>
            <div className={styles.FilterListContainer}>
              <div>
                <input type="checkbox"></input>
                <label>1"</label>
              </div>
              <div>
                <input type="checkbox"></input>
                <label>25</label>
              </div>
              <div>
                <input type="checkbox"></input>
                <label>25(1")</label>
              </div>
              <div>
                <input type="checkbox"></input>
                <label>C4</label>
              </div>
              <div>
                <input type="checkbox"></input>
                <label>C5</label>
              </div>
              <div>
                <input type="checkbox"></input>
                <label>C6</label>
              </div>
              <div>
                <input type="checkbox"></input>
                <label>ER 11</label>
              </div>
              <div>
                <input type="checkbox"></input>
                <label>ER 16</label>
              </div>
              <div>
                <input type="checkbox"></input>
                <label>SH 32</label>
              </div>
              <div>
                <input type="checkbox"></input>
                <label>VDI 40</label>
              </div>
            </div>
            <div className={styles.SpecHeading} style={{ fontSize: "8px" }}>
              <h1>Coolant Supply</h1>
            </div>
            <div className={styles.FilterListContainer}>
              <div>
                <input type="checkbox"></input>
                <label>1"</label>
              </div>
              <div>
                <input type="checkbox"></input>
                <label>25</label>
              </div>
              <div>
                <input type="checkbox"></input>
                <label>25(1")</label>
              </div>
              <div>
                <input type="checkbox"></input>
                <label>C4</label>
              </div>
              <div>
                <input type="checkbox"></input>
                <label>C5</label>
              </div>
              <div>
                <input type="checkbox"></input>
                <label>C6</label>
              </div>
              <div>
                <input type="checkbox"></input>
                <label>ER 11</label>
              </div>
              <div>
                <input type="checkbox"></input>
                <label>ER 16</label>
              </div>
              <div>
                <input type="checkbox"></input>
                <label>SH 32</label>
              </div>
              <div>
                <input type="checkbox"></input>
                <label>VDI 40</label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SelectTool;
