import React from "react";
import { useNavigate } from "react-router-dom";
import styles from "../../styles/SelectManufacturer.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHandPointRight, 
  faBook,
  faTimes,
  faQuestion,
  faListUl,
  faCheck,
} from "@fortawesome/free-solid-svg-icons";

function PageFlowMachineTool({
  Step,
  setStep,
  Manu,
  Type,
  Spec,
  Category,
  Tool,
}) {
  const navigate = useNavigate();
  const handleBack = () => {
    navigate("/");
    setStep(1);
    // localStorage.clear();
  };
  const handleClickManu = () => {
    navigate("/accessories");
    setStep(1);
    localStorage.removeItem("ManuName");
    localStorage.removeItem("ManuCode");
    localStorage.removeItem("Step");
  };
  const handleClickType = () => {
    navigate("/accessories/machine-type");
    setStep(2);
    localStorage.removeItem("TypeName");
    localStorage.removeItem("TypeCode");
    localStorage.setItem("Step", 2);
  };
  // const handleClickSpec = () => {
  //   navigate("/machine-tool/spec");
  //   setStep(3);
  // };
  const handleClickCategory = () => {
    setStep(3);
    navigate("/accessories/select-category");
    localStorage.removeItem("CategoryName");
    localStorage.removeItem("CategoryCode");
    localStorage.setItem("Step", 3);
  };
  const handleClickTool = () => {
    navigate("/accessories/select-tool");
    setStep(4);
    localStorage.removeItem("ToolName");
    // localStorage.removeItem("TypeCode");
    localStorage.setItem("Step", 4);
  };

  // console.log(Step);
  return (
    <div className={styles.PageFlowContainer} style={{ height: "360px" }}>
      <div className={styles.ProgressBar}>
        <div
          className={styles.Progress}
          style={{
            height:
              Step === 1
                ? "20%"
                : Step === 2
                  ? "40%"
                  : Step === 3
                    ? "60%"
                    : Step === 4
                      ? "80%"
                      : "100%",
          }}
        ></div>
      </div>
      <div className={styles.PageFlowElement} onClick={handleBack}>
         <FontAwesomeIcon
            style={{ color: "#275207"}}
            icon={faHandPointRight}
            className={styles.fontChange}
          /> 
        <p style={{ marginBottom: "0px" }}>Back to main catalog</p>
        <div
          className={styles.IconContainer}
          style={{
            backgroundColor: "#275207",
            height: "65px",
            width: "65px",
            marginBottom: "0px",
            marginTop: "0px",
          }}
        >
          <FontAwesomeIcon
            icon={faBook}
            className={styles.ElementIcon}
            style={{ height: "65px", width: "35px", fontSize: "30px" }}
          />
        </div>
        <p></p>
      </div>
      <div className={styles.PageFlowElement}>
        
        {Step <= 1 ? (
          <>
          <FontAwesomeIcon
            style={{ color: "#275207"}}
            icon={faHandPointRight}
            className={styles.fontChange}
          /> 
          <p>Select manufacturer</p>
            <div
              className={styles.IconContainer}
              style={{ backgroundColor: "#c70b0b" }}
            >
              <FontAwesomeIcon
                icon={faQuestion}
                className={styles.ElementIcon}
              />
            </div>
            <p></p>
          </>
        ) : (
          <>
          <FontAwesomeIcon
            style={{ color: "#275207"}}
            icon={faHandPointRight}
            className={styles.fontChange}
          /> 
          <p  onClick={handleClickManu}>Select manufacturer</p>
            <div
              className={styles.IconContainer}
              onClick={handleClickManu}
              style={{ backgroundColor: "#275207" }}
            >
              <FontAwesomeIcon icon={faCheck} className={styles.ElementIcon} />
            </div>
            <p style={{ height: "0px",color:"#275207" }}>
              {Manu ? Manu.name : localStorage.getItem("ManuName")}
            </p>
          </>
        )}
      </div>
      <div className={styles.PageFlowElement}>
        {Step === 2 ? (
          <>
          <FontAwesomeIcon
            style={{ color: "#275207"}}
            icon={faHandPointRight}
            className={styles.fontChange}
          /> 
          <p>Select machine model</p>
            <div
              className={styles.IconContainer}
              style={{ backgroundColor: "#c70b0b" }}
            >
              <FontAwesomeIcon
                icon={faQuestion}
                className={styles.ElementIcon}
              />
            </div>
            <p></p>
          </>
        ) : Step > 2 ? (
          <>
          <FontAwesomeIcon
            style={{ color: "#275207"}}
            icon={faHandPointRight}
            className={styles.fontChange}
          /> 
          <p onClick={handleClickType}>Select machine model</p>
            <div
              className={styles.IconContainer}
              style={{ backgroundColor: "#275207" }}
              onClick={handleClickType}
            >
               
              <FontAwesomeIcon  icon={faCheck} className={styles.ElementIcon} />
            </div>
            <p title={Type.name} style={{ height: "0px" ,color:"#275207" }}>
              {Type ? Type.name.substring(0,16) : localStorage.getItem("TypeName")}..
            </p>
          </>
        ) : (
          <>
          <FontAwesomeIcon
            style={{ color: "#275207"}}
            icon={faHandPointRight}
            className={styles.fontChange}
          /> 
          <p>Select machine model</p>
            <div className={styles.IconContainer}>
              <FontAwesomeIcon icon={faTimes} className={styles.ElementIcon} />
            </div>
            <p></p>
          </>
        )}
      </div>
      {/* <div className={styles.PageFlowElement}>
        <p>Select specification</p>
        {Step === 3 ? (
          <div
            className={styles.IconContainer}
            style={{ backgroundColor: "#c70b0b" }}
          >
            <FontAwesomeIcon icon={faQuestion} className={styles.ElementIcon} />
          </div>
        ) : Step > 3 ? (
          <>
            <div
              className={styles.IconContainer}
              style={{ backgroundColor: "#a4d62c" }}
              onClick={handleClickSpec}
            >
              <FontAwesomeIcon icon={faCheck} className={styles.ElementIcon} />
            </div>
            <p style={{ height: "0px" }}>{Spec}</p>
          </>
        ) : (
          <div className={styles.IconContainer}>
            <FontAwesomeIcon icon={faTimes} className={styles.ElementIcon} />
          </div>
        )}
      </div> */}
      <div className={styles.PageFlowElement}>
      
        {Step === 3 ? (
          <>
          <FontAwesomeIcon
            style={{ color: "#275207"}}
            icon={faHandPointRight}
            className={styles.fontChange}
          /> 
            <p>Select Product Family</p>
            <div
              className={styles.IconContainer}
              style={{ backgroundColor: "#c70b0b" }}
            >
              <FontAwesomeIcon
                icon={faQuestion}
                className={styles.ElementIcon}
              />
            </div>
            <p></p>
          </>
        ) : Step > 3 ? (
          <>
          <FontAwesomeIcon
            style={{ color: "#275207"}}
            icon={faHandPointRight}
            className={styles.fontChange}
          /> 
          <p onClick={handleClickCategory}>Select Product Family</p>
            <div
              className={styles.IconContainer}
              style={{ backgroundColor: "#275207" }}
              onClick={handleClickCategory}
            >
              <FontAwesomeIcon icon={faCheck} className={styles.ElementIcon} />
            </div>
            <p style={{ height: "0px",color:"#275207" }}>
              {Category ? Category.name.substring(0,16) : localStorage.getItem("CategoryName")}..
            </p>
          </>
        ) : (
          <>
          <FontAwesomeIcon
            style={{ color: "#275207"}}
            icon={faHandPointRight}
            className={styles.fontChange}
          /> 
          <p>Select Product Family</p>
            <div className={styles.IconContainer}>
              <FontAwesomeIcon icon={faTimes} className={styles.ElementIcon} />
            </div>
            <p></p>
          </>
        )}
      </div>
      <div className={styles.PageFlowElement}>
        
        {Step === 4 ? (
          <>
          <FontAwesomeIcon
            style={{ color: "#275207"}}
            icon={faHandPointRight}
            className={styles.fontChange}
          /> 
          <p>Select Tool</p>
            <div
              className={styles.IconContainer}
              style={{ backgroundColor: "#275207" }}
            >
              <FontAwesomeIcon
                icon={faQuestion}
                className={styles.ElementIcon}
              />
            </div>
            <p></p>
          </>
        ) : Step > 4 ? (
          <>
          <FontAwesomeIcon
            style={{ color: "#275207"}}
            icon={faHandPointRight}
            className={styles.fontChange}
          /> 
          <p  onClick={handleClickTool}>Select Tool</p>
            <div
              className={styles.IconContainer}
              style={{ backgroundColor: "#275207" }}
              onClick={handleClickTool}
            >
              <FontAwesomeIcon icon={faCheck} className={styles.ElementIcon} />
            </div>
            <p style={{ height: "0px",color:"#275207" }}>
              {Tool ? Tool.articleId : localStorage.getItem("ToolName")}
            </p>
          </>
        ) : (
          <>
          <FontAwesomeIcon
            style={{ color: "#275207"}}
            icon={faHandPointRight}
            className={styles.fontChange}
          /> 
          <p>Select Tool</p>
            {" "}
            <div className={styles.IconContainer}>
              <FontAwesomeIcon icon={faTimes} className={styles.ElementIcon} />
            </div>
            <p></p>
          </>
        )}
      </div>
      <div className={styles.PageFlowElement}>
        
        <p style={{ marginBottom: "0px" }}>Tool details</p>
        {Step === 5 ? (
          <>
            <div
              className={styles.IconContainer}
              style={{
                height: "65px",
                width: "65px",
                marginBottom: "0px",
                marginTop: "0px",
                backgroundColor: "#275207",
              }}
            >
              <FontAwesomeIcon
                icon={faListUl}
                className={styles.ElementIcon}
                style={{ height: "65px", width: "35px", fontSize: "30px" }}
              />
            </div>
            <p></p>
          </>
        ) : (
          <>
            <div
              className={styles.IconContainer}
              style={{
                height: "65px",
                width: "65px",
                marginBottom: "0px",
                marginTop: "0px",
              }}
            >
              <FontAwesomeIcon
                icon={faListUl}
                className={styles.ElementIcon}
                style={{ height: "65px", width: "35px", fontSize: "30px" }}
              />
            </div>
            <p></p>
          </>
        )}
      </div>
    </div>
  );
}

export default PageFlowMachineTool;
