import React from "react";
import Card from "../components/Card";
import styles from "../styles/HomePage.module.css";
import img1 from "../assets/top-left.png"

function HomePage(props) {
  return (
    <>
    <div className={styles.top_left_Image}>
    <img width="19%" src={img1}/> 
    </div>
    <div className={styles.HomePage}>
     
      <div className={styles.TopDiv}>
        <h2>
          <b>FIND YOUR TOOL HOLDERS</b>
        </h2>
        <p></p>
      </div>
      <div className={styles.CardParent}>
        <Card
         route="article-number"
          CardHead={"Search by Article number"}
          CardInfo={"With the article number, direct to the tool information."}
          CardIMG={
            "https://ik.imagekit.io/0rsvz9vomoe/Test_image/TOOL-HOLDERS1__1__rijcpnrN0.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1663316478779"
          }
          // onClick={clearHistory}
         
        />
        <Card
          CardHead={"Search by Machine"}
          CardInfo={"Select manufacturer, the alphabet list of manufacturers."}
          CardIMG={
            "https://ik.imagekit.io/0rsvz9vomoe/Test_image/TOOL-HOLDERS2__1__MsUUiNqWaS.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1663316478787"
          }
          route="machine-tool"
        />
        <Card
          CardHead={"Search by Turret"}
          CardInfo={
            "Initial setup proposals for machines for download as PDF with quantity recommendation."
          }
          CardIMG={
            "https://ik.imagekit.io/0rsvz9vomoe/Test_image/TOOL-HOLDERS3__1__4ayMuPiN1.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1663316478711"
          }
          // route="turrets"
          route="turrets"
        />
        <Card
          CardHead={"Search Accessories to Tool Holders"}
          CardInfo={
            "Special tooling from Sphoorti specifically grouped together for your machine."
          }
          CardIMG={
            "https://ik.imagekit.io/0rsvz9vomoe/Test_image/TOOL-HOLDERS__1__eENidabah.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1663316478716"
          }
          route="accessories"
          // route="#"
        />
      </div>
      {/* <div class="full_div nopadding clearfix mrgnbtm">
        <div class="fltr_box" title="Click to download pdf">
          <a href="/OnlineCatalogue/SearchBySpecialTooling/Step1">
            <div class="flitr_innerbox">
              <p>
                <b>Special tooling</b>
              </p>
              <img
                src="https://ewswebstorageprod.blob.core.windows.net/media/Default/images/initial_setup.png"
                width="85%"
                height="auto"
                alt="EWS Special Tooling"
              />
              <p class="fltr_info">
                Special tooling from EWS specifically grouped together for your
                machine. For download and observation in the browser.
              </p>
            </div>
          </a>
        </div>
      </div> */}
    </div>
    </>
  );
}

export default HomePage;
