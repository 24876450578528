import React, { useEffect, useState } from "react";
import styles from "../../styles/SelectManufacturer.module.css";
import { useNavigate } from "react-router-dom";
import PageFlowMachineTool from "./PageFlowMachineTool";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { faHandPointRight } from "@fortawesome/free-solid-svg-icons";
import { faFilter } from "@fortawesome/free-solid-svg-icons";

function SelectCategory({ Step, setStep, Manu, Type, Spec, setCategory }) {
  let navigate = useNavigate();
  const handleSelectCategory = (element) => {
    setStep(4);
    navigate(`/accessories/select-tool`);
    setCategory(element);
    localStorage.setItem("CategoryCode", element.code);
    localStorage.setItem("CategoryName", element.name);
    localStorage.setItem("Step", 4);
  };
  const alphabetArray = "abcdefghijklmnopqrstuvwxyz".split("");
  const [Data, setData] = useState([]);
  const [Loading, setLoading] = useState(true);
  const [Filters, setFilters] = useState(false);
  const [PostData, setPostData] = useState({
    modelCode: `${Type ? Type.code : localStorage.getItem("TypeCode")}`,
    filters: [],
  });
  // const postThis = localStorage.getItem("selectCatFilters")
  //   ? JSON.parse(localStorage.getItem("selectCatFilters")).modelCode ===
  //     localStorage.getItem("TypeCode")
  //     ? JSON.parse(localStorage.getItem("selectCatFilters"))
  //     : PostData
  //   : PostData;
  // const handleFilter = (filter, category) => {
  //   const isSameElement = (element) => element.category === category;
  //   const isStringElement = (element) => element.category === "string";
  //   const isSameFilter = (element) => element === filter;
  //   setLoading(true);
  //   setData(null);
  //   postThis.filters.some((element) => element.category === "string") &&
  //     postThis.filters.splice(postThis.filters.findIndex(isStringElement), 1);
  //   const object = {
  //     category: `${category}`,
  //     filters: [`${filter}`],
  //   };
  //   postThis.filters.some((element) => element.category === category)
  //     ? postThis.filters[
  //         postThis.filters.findIndex(isSameElement)
  //       ].filters.some((element) => element === filter)
  //       ? postThis.filters[
  //           postThis.filters.findIndex(isSameElement)
  //         ].filters.splice(
  //           postThis.filters[
  //             postThis.filters.findIndex(isSameElement)
  //           ].filters.findIndex(isSameFilter),
  //           1
  //         )
  //       : postThis.filters[
  //           postThis.filters.findIndex(isSameElement)
  //         ].filters.push(filter)
  //     : postThis.filters.push(object);

  //   for (let i = 0; i < postThis.filters.length; i++) {
  //     if (postThis.filters[i].filters.length === 0) {
  //       postThis.filters.splice(i, 1);
  //     }
  //   }

  //   setPostData(postThis);
  //   localStorage.setItem("selectCatFilters", JSON.stringify(postThis));
  //   console.log(postThis);
  //   axios
  //     .post(
  //       "https://sphoortitoolscatalogapi20211217232946.azurewebsites.net/tools/api/v1/catalogs/tool-models/tool-categories",
  //       postThis
  //     )
  //     .then((res) => {
  //       setData(res.data.data);
  //       setLoading(false);
  //     });
  // };
  useEffect(() => {
    setStep(JSON.parse(localStorage.getItem("Step")));
    axios
      .get(
        `https://sphoortitoolscatalogapi20211217232946.azurewebsites.net/tools/api/v1/catalogs/accessory-categories/${Type ? Type.code : localStorage.getItem("TypeCode")
        }/accessory-families`
      )
      .then((res) => {
        // setFilters(res.data.data);
        setData(res.data.data)
      });
    // console.log(Data);
  }, []);
  // useEffect(() => {
  //   axios
  //     .post(
  //       "https://sphoortitoolscatalogapi20211217232946.azurewebsites.net/tools/api/v1/catalogs/tool-models/tool-categories",
  //       localStorage.getItem("selectCatFilters")
  //         ? JSON.parse(localStorage.getItem("selectCatFilters")).modelCode ===
  //           localStorage.getItem("TypeCode")
  //           ? JSON.parse(localStorage.getItem("selectCatFilters"))
  //           : PostData
  //         : PostData
  //     )
  //     .then((res) => {
  //       setData(res.data.data);
  //       setLoading(false);
  //     });
  //   console.log(JSON.parse(localStorage.getItem("selectCatFilters")));
  // }, []);
  // const Arr = ["Static tools", "Driven tools"];

const hello=()=>
{
  console.log(hello)

}

  return (
    <div className={`${styles.HomePage} ${styles.MachineToolsThird}`}>
      <div className={styles.PageFlowWrapper}>
        <div className={styles.TopDiv}>
          <h2>
            <b>Search Tool Holder Acessories</b>
          </h2>
        </div>
        <PageFlowMachineTool
          Step={Step}
          setStep={setStep}
          Manu={Manu}
          Type={Type}
          Spec={Spec}
        />
      </div>
      <div className={styles.PageContentWrapper}>
        <div className={styles.TopDiv}>
          <h2>
            <b>Select Product Family by Clicking</b>
          </h2>
          {/* <p>
            Select from the following categories to obtain a list with EWS tools
            for your machine.
          </p> */}
        </div>
        {/* <div className={styles.ToolDictionaryContainer} style={{ marginBottom: "10px" }}>
          <ul className={styles.ToolListContainer}>
            {Loading && <p>Loading...</p>}
            {!Loading && alphabetArray.map((alpha) => {
              return (
                <>
                  {Data.some((element) =>
                    element.name.startsWith(alpha.toUpperCase())
                  ) &&
                    (
                      <li style={{ width: "4%" }}>
                        <a href={`#${alpha}`}>{alpha.toUpperCase()}</a>
                      </li>
                    )
                  }
                </>
              );
            })}
          </ul>
        </div> */}
        <div
          className={styles.DictionaryContainer}
          style={{ marginTop: "50px" }}
        >
          {/* {Loading && <p>Loading...</p>} */}
          {alphabetArray.map((alpha, i) => {
            return (
              <>
                {Data &&
                  Data.some((element) =>
                    element.name.startsWith(alpha.toUpperCase())
                  ) && (
                    <div
                      className={styles.AlphaContainer}
                      key={i}
                      id={`${alpha}`}
                    >
                      <FontAwesomeIcon
                        style={{ color: "green" }}
                        icon={faHandPointRight}
                        className={styles.ElementIcon}
                      />
                      <b style={{ visibility: "hidden" }}>
                        {alpha.toUpperCase()}
                      </b>
                      <ul className={styles.onListHover}>
                        {Data.map((element, index) => {
                          return (
                            element.name.startsWith(alpha.toUpperCase()) && (
                              <li key={index}>
                                {
                                  <div
                                    onClick={() =>
                                      handleSelectCategory(element)
                                    }
                                  // style={{
                                  //   top: "-20%",
                                  //   marginBottom: "-35px",
                                  // }}
                                  >
                                    <img
                                    
                                      alt="product"
                                      src={element.image.bigImageURL}                                      style={{
                                        maxHeight: "fit-content",
                                        width: "50%",
                                      }}
                                    ></img>
                                    <span key={index}>{element.name}</span>
                                  </div>
                                }
                              </li>
                            )
                          );
                        })}
                      </ul>
                    </div>
                  )}
              </>
            );
          })}
        </div>
        {/* <div className={styles.ToolDictionaryContainer} style={{ marginBottom: "10px" }}>
          <ul className={styles.ToolListContainer}>
            {Loading && <p>Loading...</p>}
            {!Loading && alphabetArray.map((alpha) => {
              return (
                <>
                  {Data.some((element) =>
                    element.name.startsWith(alpha.toUpperCase())
                  ) &&
                    (
                      <li style={{ width: "4%" }}>
                        <a href={`#${alpha}`}>{alpha.toUpperCase()}</a>
                      </li>
                    )
                  }
                </>
              );
            })}
          </ul>
        </div> */}
      </div>
      {/* <div className={styles.FilterWrapper}>
        <div className={styles.TopDiv} style={{ display: "flex" }}>
          <h2>
            <b>Filter By Parameter</b>
          </h2>
          <FontAwesomeIcon
            style={{ color: "green" }}
            icon={faFilter}
            className={styles.ElementIcon}
          />
        </div>
        <div
          className={styles.SpecDictionaryContainer}
          style={{ marginTop: "50px" }}
        >
          <div className={styles.SpecContainer}>
            {Filters &&
              Filters.map((element, i) => {
                return (
                  <>
                    <div
                      className={styles.SpecHeading}
                      style={{ fontSize: "8px" }}
                    >
                      <h1>{element.category}</h1>
                    </div>
                    <div
                      className={styles.FilterListContainer}
                      style={{
                        borderBottom: i === Filters.length - 1 ? "none" : "",
                      }}
                    >
                      {element.filters.map((filt) => {
                        return (
                          <div>
                            <input
                              type="checkbox"
                              onClick={() =>
                                // handleFilter(filt, element.category)
                              }
                              defaultChecked={
                                localStorage.getItem("selectCatFilters") &&
                                JSON.parse(
                                  localStorage.getItem("selectCatFilters")
                                ).modelCode ===
                                  localStorage.getItem("TypeCode") &&
                                JSON.parse(
                                  localStorage.getItem("selectCatFilters")
                                ).filters.some(
                                  (el) => el.category === element.category
                                )
                                  ? JSON.parse(
                                      localStorage.getItem("selectCatFilters")
                                    ).filters[
                                      JSON.parse(
                                        localStorage.getItem("selectCatFilters")
                                      ).filters.findIndex(
                                        (el) => el.category === element.category
                                      )
                                    ].filters.some((el) => el === filt)
                                  : false
                              }
                            ></input>
                            <label>{filt}</label>
                          </div>
                        );
                      })}
                    </div>
                  </>
                );
              })}
          </div>
        </div>
      </div> */}
    </div>
  );
}

export default SelectCategory;
