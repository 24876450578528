import React, { useState } from "react";
import styles from "../../styles/SelectManufacturer.module.css";
import { useNavigate } from "react-router-dom";
import PageFlowArticle from "./PageFlowArticle";
import axios from "axios";

function Search({ Step, setStep, setArticle, setTool, setToolDetail }) {
  let navigate = useNavigate();
  const [Input, setInput] = useState();
  const [Undefined, setUndefined] = useState(false);
   const [buttonClick, setButtonClick] =  useState(false)
  const handleArticleSearch = (e) => {
    setButtonClick(true)
    e.preventDefault();
    axios
      .get(
        `https://sphoortitoolscatalogapi20211217232946.azurewebsites.net/tools/api/v1/catalogs/tools/${Input.toUpperCase()}`
      )
      .then((res) => {
        console.log(res.data.data);
        setUndefined(false);
        res.data.data === undefined && setUndefined(true);
        res.data.data !== undefined && setStep(3);
        res.data.data !== undefined && localStorage.setItem("Step", 3);
        res.data.data !== undefined && setArticle(Input.toUpperCase());
        res.data.data !== undefined &&
          localStorage.setItem("Article", Input.toUpperCase());
        res.data.data !== undefined && setTool(JSON.stringify(res.data.data));
        res.data.data !== undefined &&
          localStorage.setItem("ToolMain", JSON.stringify(res.data.data));
        res.data.data !== undefined &&
          localStorage.setItem("ToolName", res.data.data.displayName);
        res.data.data !== undefined &&
          localStorage.setItem("ToolImage", res.data.data.images.bigImageURL);
        res.data.data !== undefined &&
          localStorage.setItem("ToolFeature", res.data.data.feature.value);
        res.data.data !== undefined &&
        localStorage.setItem("CategoryName", res.data.data.productCategory.name);
        res.data.data !== undefined &&
          localStorage.setItem(
            "ToolMachine",
            JSON.stringify(res.data.data.machineReferences)
          );
        res.data.data !== undefined &&
          localStorage.setItem("ToolDocs", JSON.stringify(res.data.data.docs));
        res.data.data !== undefined && setTool(res.data.data);
        res.data.data !== undefined && navigate(`/article-number/tool-details`);

        // setToolDetail(res.data.data);
      })
      .catch((err) => {
        console.log(err);
        setUndefined(true);
      });
    // setStep(2);
    // navigate(`/article-number/select-tool`);
    // setArticle(element);
  };
  const alphabetArray = "abcdefghijklmnopqrstuvwxyz".split("");
  const Arr = [
    "Accuway",
    "Alex-Tech",
    "Angelini",
    "Avia",
    "Baruffaldi",
    "BATLIBOI",
    "Benzinger",
    "Biglia",
    "Boehringer",
    "Boley",
    "Cacta",
    "Campro",
    "Cazeneuve",
    "Chevalier",
    "CHIAH CHYUN",
    "CHIN HUNG",
    "Cincinnati",
    "CMZ",
    "CNC Takang",
    "Colchester",
    "Contur",
    "MAG",
    "Magdeburger",
    "Maho Graziano",
    "MAS Kovosvit",
    "MAZAK",
    "Monforts",
    "Mori Seiki",
    "Muratec",

    "Nakamura",
    "NCT",
    "Niles",
    "OKUMA",
    "Realmeca",
    "Romi",
    "YCM",
    "YEONG CHIN",
  ];
  return (
    <div className={`${styles.HomePage} ${styles.SearchHomePage}`}>
      <div className={styles.PageFlowWrapper}>
        <div className={styles.TopDiv}>
          <h2>
            <b>Search Your Tool Holder</b>
          </h2>
        </div>
        <PageFlowArticle Step={Step} setStep={setStep} />
      </div>

      <div className={styles.PageContentWrapper}>
        <div className={styles.TopDiv} style={{ height: "38px" }}></div>
        <h2 align="center" style={{ color: "#648467", align: "center" }}>
          <b>Search by Article Number</b>
        </h2>
        <div className={styles.SearchModal}>
          <div className={styles.SearchContainer}>
            <input
              placeholder="Write article number"
              onChange={(e) => setInput(e.target.value)}
            ></input>
            <button onClick={(e) => handleArticleSearch(e) } disabled={buttonClick  }>Search</button>
          </div>
          {Undefined && <h2>No results found.</h2>}
          <p>Use the Article number to search for the required Tool Holder.</p>
        </div>
      </div>
    </div>
  );
}

export default Search;
