import React, { useEffect, useState } from "react";
import styles from "../../styles/SelectManufacturer.module.css";
import { useNavigate } from "react-router-dom";
import PageFlowMachineTool from "./PageFlowMachineTool";
import axios from "axios";
import { faHandPointRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function Manufacturer({ Step, setStep, setManu }) {
  let navigate = useNavigate();
  const handleSelectManu = (element) => {
    setStep(2);
    navigate(`/accessories/machine-type`);
    setManu(element);
    localStorage.setItem("accessory", JSON.stringify(element));
    localStorage.setItem("ManuName", element.name);
    localStorage.setItem("ManuImg", element.imageUrl);
    localStorage.setItem("Step", 2);
  };
  const alphabetArray = "abcdefghijklmnopqrstuvwxyz".split("");
  const [Data, setData] = useState([]);
  const [Loading, setLoading] = useState(true);
  const Arr = [
    "Accuway",
    "Alex-Tech",
    "Angelini",
    "Avia",
    "Baruffaldi",
    "BATLIBOI",
    "Benzinger",
    "Biglia",
    "Boehringer",
    "Boley",
    "Cacta",
    "Campro",
    "Cazeneuve",
    "Chevalier",
    "CHIAH CHYUN",
    "CHIN HUNG",
    "Cincinnati",
    "CMZ",
    "CNC Takang",
    "Colchester",
    "Contur",
    "MAG",
    "Magdeburger",
    "Maho Graziano",
    "MAS Kovosvit",
    "MAZAK",
    "Monforts",
    "Mori Seiki",
    "Muratec",
    "Nakamura",
    "NCT",
    "Niles",
    "OKUMA",
    "Realmeca",
    "Romi",
    "YCM",
    "YEONG CHIN",
  ];
  useEffect(() => {
    axios
      .get(
        "https://sphoortitoolscatalogapi20211217232946.azurewebsites.net/tools/api/v1/catalogs/accessory-categories"
      )
      .then((res) => {
        setData(res.data.data);
        setLoading(false);
      });
    localStorage.removeItem("ManuName");
    setStep(1);
  }, []);
  return (
    <div className={`${styles.HomePage} ${styles.machineToolFirst}`}>
      <div className={styles.PageFlowWrapper}>
        <div className={styles.TopDiv}>
          <h2>
            <b>Search Tool Holder Acessories</b>
          </h2>
        </div>
        <PageFlowMachineTool Step={Step} setStep={setStep} />
      </div>
      <div className={styles.PageContentWrapper}>
        <div className={styles.TopDiv}>
          <h2>
            <b>Select manufacturer by Clicking</b>
          </h2>
          {/* <p>
            Select from the following manufacturers to obtain a list with EWS
            tools for your machine.
          </p> */}
        </div>

        <div
          className={styles.ToolDictionaryContainer}
          style={{ marginBottom: "10px", marginTop: "50px" }}
        >
          <FontAwesomeIcon
            style={{ color: "#275207" }}
            icon={faHandPointRight}
            className={styles.ElementIcon}
          />
          <ul className={styles.ToolListContainer}>
            {Loading && <p>Loading...</p>}
            {!Loading &&
              alphabetArray.map((alpha) => {
                return (
                  <>
                    {Data.some((element) =>
                      element.name.startsWith(alpha.toUpperCase())
                    ) && (
                        <li style={{ width: "4%" }}>
                          <a href={`#${alpha}`}>{alpha.toUpperCase()}</a>
                        </li>
                      )}
                  </>
                );
              })}
          </ul>
        </div>
        <div className={styles.DictionaryContainer}>
          {Loading && <p>Loading...</p>}
          {alphabetArray.map((alpha, i) => {
            return (
              <>
                {Data.some((element) =>
                  element.name.startsWith(alpha.toUpperCase())
                ) && (
                    <div
                      className={styles.AlphaContainer}
                      key={i}
                      id={`${alpha}`}
                    >
                      <FontAwesomeIcon
                        style={{ color: "#275207" }}
                        icon={faHandPointRight}
                        className={styles.ElementIcon}
                      />
                      <b style={{ visibility: "hidden" }}>
                        {alpha.toUpperCase()}
                      </b>
                      <ul className={styles.onListHover}>
                        {Data.map((element, index) => {
                          console.log("suman");
                          console.log(element)
                          return (
                            element.name.startsWith(alpha.toUpperCase()) && (
                              <li key={index}>
                                {
                                  // <div>
                                  // {/* <img
                                  //   style={{ width: "60%" }}
                                  //   alt="manufacturer"
                                  //   src={element.imageUrl}
                                  // ></img> */}
                                  <span
                                    key={index}
                                    onClick={() => handleSelectManu(element)}
                                  >
                                    {element.name}
                                  </span>
                                  // </div>
                                }
                              </li>
                            )
                          );
                        })}
                      </ul>
                    </div>
                  )}
              </>
            );
          })}
        </div>
        <div className={styles.ToolDictionaryContainer}>
          <ul className={styles.ToolListContainer}>
            {Loading && <p>Loading...</p>}
            {!Loading &&
              alphabetArray.map((alpha) => {
                return (
                  <>
                    {Data.some((element) =>
                      element.name.startsWith(alpha.toUpperCase())
                    ) && (
                        <li style={{ width: "4%" }}>
                          <a href={`#${alpha}`}>{alpha.toUpperCase()}</a>
                        </li>
                      )}
                  </>
                  // <li>
                  //   <a href={`#${element.displayName}`}>{element.displayName}</a>
                  // </li>
                );
              })}
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Manufacturer;
