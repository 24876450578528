import { useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import HomePage from "./pages/HomePage";
import Manufacturer from "./pages/SelectMachineTool/Manufacturer";
import TurretManufacturer from "./pages/SelectTurrets/Manufacturer";
import AccessoriesManufacturer from "./pages/Accessories/Manufacturer";
import MachineType from "./pages/SelectMachineTool/MachineType";
import TurretMachineType from "./pages/SelectTurrets/MachineType";
import AccessoriesMachineType from "./pages/Accessories/MachineType";
import ToolSpec from "./pages/SelectMachineTool/ToolSpec";
import SelectCategory from "./pages/SelectMachineTool/SelectCategory";
import TurretSelectCategory from "./pages/SelectTurrets/SelectCategory";
import AccessoriesSelectCategory from "./pages/Accessories/SelectCategory";
import SelectTool from "./pages/SelectMachineTool/SelectTool";
import TurretSelectTool from "./pages/SelectTurrets/SelectTool";
import AccessoriesSelectTool from "./pages/Accessories/SelectTool";
import ArticleSelectTool from "./pages/WithArticleNum/SelectTool";
import ArticleToolDetails from "./pages/WithArticleNum/ToolDetails";
import ToolDetails from "./pages/SelectMachineTool/ToolDetails";
import TurretToolDetails from "./pages/SelectTurrets/ToolDetails";
import AccessoriesToolDetails from "./pages/Accessories/ToolDetails";
import Search from "./pages/WithArticleNum/Search";
import Navbar from "./components/Navbar";
import WatchList from "./pages/WatchList/Index";
import CompareList from "./pages/CompareList/Index";

function App() {
  const [Step, setStep] = useState(1);
  const [Manu, setManu] = useState("");
  const [Type, setType] = useState("");
  const [Spec, setSpec] = useState("");
  const [Category, setCategory] = useState("");
  const [Tool, setTool] = useState("");
  const [ToolDetail, setToolDetail] = useState("");
  const [Article, setArticle] = useState("");
  return (
    <div className="App">
      {/* {window.innerWidth > 1000 && <div style={{position:"absolute" ,zIndex:'1' , top:"95%" , left:"5%"}}>
        <p><strong>Designed</strong> and <strong>Developed</strong> by <strong>
          <a href="https://digikit.in/" style={{textDecoration:"none",color:"#f05a29"}}>DIGIKIT</a>
          </strong></p>
        </div>} */}
      <Router>
        <Navbar />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route
            path="/machine-tool"
            element={
              <Manufacturer Step={Step} setStep={setStep} setManu={setManu} />
            }
          ></Route>
          <Route
            path="/machine-tool/machine-type"
            element={
              <MachineType
                Step={Step}
                setStep={setStep}
                Manu={Manu}
                setManu={setManu}
                setType={setType}
              />
            }
          ></Route>
          <Route
            path="/machine-tool/spec"
            element={
              <ToolSpec
                Step={Step}
                setStep={setStep}
                Manu={Manu}
                Type={Type}
                setSpec={setSpec}
              />
            }
          ></Route>
          <Route
            path="/machine-tool/select-category"
            element={
              <SelectCategory
                Step={Step}
                setStep={setStep}
                Manu={Manu}
                Type={Type}
                Spec={Spec}
                setCategory={setCategory}
              />
            }
          ></Route>
          <Route
            path="/machine-tool/select-tool"
            element={
              <SelectTool
                Step={Step}
                setStep={setStep}
                Manu={Manu}
                Type={Type}
                Spec={Spec}
                Category={Category}
                setTool={setTool}
                setToolDetail={setToolDetail}
              />
            }
          ></Route>
          <Route
            path="/machine-tool/tool-details"
            element={
              <ToolDetails
                Step={Step}
                setStep={setStep}
                Manu={Manu}
                Type={Type}
                Spec={Spec}
                Category={Category}
                Tool={Tool}
                setTool={setTool}
                ToolDetail={ToolDetail}
              />
            }
          ></Route>
          <Route
            path="/article-number"
            element={
              <Search
                Step={Step}
                setStep={setStep}
                setArticle={setArticle}
                setTool={setTool}
                setToolDetail={setToolDetail}
              />
            }
          ></Route>
          <Route
            path="/article-number/select-tool"
            element={
              <ArticleSelectTool
                Step={Step}
                setStep={setStep}
                Article={Article}
                setTool={setTool}
                setToolDetail={setToolDetail}
              />
            }
          ></Route>
          <Route
            path="/article-number/tool-details"
            element={
              <ArticleToolDetails
                Step={Step}
                setStep={setStep}
                setTool={setTool}
                Article={Article}
                Tool={Tool}
                ToolDetail={ToolDetail}
              />
            }
          ></Route>
          <Route
            path="/turrets"
            element={
              <TurretManufacturer
                Step={Step}
                setStep={setStep}
                setManu={setManu}
              />
            }
          ></Route>
          <Route
            path="/turrets/machine-type"
            element={
              <TurretMachineType
                Step={Step}
                setStep={setStep}
                Manu={Manu}
                setType={setType}
              />
            }
          ></Route>
          <Route
            path="/turrets/select-category"
            element={
              <TurretSelectCategory
                Step={Step}
                setStep={setStep}
                Manu={Manu}
                Type={Type}
                Spec={Spec}
                setCategory={setCategory}
              />
            }
          ></Route>
          <Route
            path="/turrets/select-tool"
            element={
              <TurretSelectTool
                Step={Step}
                setStep={setStep}
                Manu={Manu}
                Type={Type}
                Spec={Spec}
                Category={Category}
                setTool={setTool}
                setToolDetail={setToolDetail}
              />
            }
          ></Route>
          <Route
            path="/turrets/tool-details"
            element={
              <TurretToolDetails
                Step={Step}
                setStep={setStep}
                Manu={Manu}
                Type={Type}
                Spec={Spec}
                Category={Category}
                Tool={Tool}
                ToolDetail={ToolDetail}
              />
            }
          ></Route>
          <Route
            path="/accessories"
            element={
              <AccessoriesManufacturer
                Step={Step}
                setStep={setStep}
                setManu={setManu}
              />
            }
          ></Route>
          <Route
            path="/accessories/machine-type"
            element={
              <AccessoriesMachineType
                Step={Step}
                setStep={setStep}
                Manu={Manu}
                setType={setType}
              />
            }
          ></Route>
          <Route
            path="/accessories/select-category"
            element={
              <AccessoriesSelectCategory
                Step={Step}
                setStep={setStep}
                Manu={Manu}
                Type={Type}
                Spec={Spec}
                setCategory={setCategory}
              />
            }
          ></Route>
          <Route
            path="/accessories/select-tool"
            element={
              <AccessoriesSelectTool
                Step={Step}
                setStep={setStep}
                Manu={Manu}
                Type={Type}
                Spec={Spec}
                Category={Category}
                setTool={setTool}
                setToolDetail={setToolDetail}
              />
            }
          ></Route>
          <Route
            path="/accessories/tool-details"
            element={
              <AccessoriesToolDetails
                Step={Step}
                setStep={setStep}
                Manu={Manu}
                Type={Type}
                Spec={Spec}
                Category={Category}
                Tool={Tool}
                ToolDetail={ToolDetail}
              />
            }
          ></Route>
          <Route
            path="/watch-list"
            element={<WatchList setTool={setTool} />}
          ></Route>
          <Route path="/compare-list" element={<CompareList />}></Route>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
