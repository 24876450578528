import React, { useEffect, useState } from "react";
import styles from "../../styles/SelectManufacturer.module.css";
import { useNavigate } from "react-router-dom";
import PageFlowMachineTool from "./PageFlowMachineTool";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Form, Modal } from "react-bootstrap";
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
//import 'bootstrap/dist/css/bootstrap.min.css';
import {
  faCopy,
  faSave,
  faShoppingCart,
} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";

function ToolDetails({
  Step,
  setStep,
  Manu,
  Type,
  Spec,
  Category,
  Tool,
  setTool,
  ToolDetail,
}) {
  let navigate = useNavigate();
  const handleSelectCategory = (element) => {
    setStep(4);
    navigate(`/machine-tool/spec`);
    // setCategory(element);
  };
  const [SaveDisabled, setSaveDisabled] = useState(false);
  const [CompareDisabled, setCompareDisabled] = useState(false);
  const [Data, setData] = useState([]);
  const [NewData, setNewData] = useState([]);
  const [Loading, setLoading] = useState(true);
  const [show, setShow] = useState(false);
  const [hrefValue, sethrefValue] = useState(false);
  const [EmailValue, setEmailValue] = useState("");
  const [NameValue, setNameValue] = useState("");
  const [CompanyNameValue, setCompanyNameValue] = useState("");
  const [MobileValue, setMobileValue] = useState("");

  const handleClose = () => {
    setShow(false)
    sethrefValue(null)
  };
  const handleSubmit = () => {
    setShow(false);
    var payload = {
      service_id: "service_s06nym8",
      template_id: "template_6tk4x9g",
      user_id: "user_znuNUI2JayN2YmMJCVqkd",
      template_params: {
        html_message: `
          customer email: ${EmailValue},
          customer name: ${NameValue},
          company name: ${CompanyNameValue},
          mobile number: ${MobileValue}
        `,
        mail_title: `
          Customer info from catalogue
        `,
      },
    };
    axios({
      method: "post",
      url: "https://api.emailjs.com/api/v1.0/email/send",
      data: JSON.stringify(payload), // you are sending body instead
      headers: {
        // 'Authorization': `bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
    window.open(
      `${hrefValue}`
    );
  };

  const handleShow = (val) => {
    setShow(true)
    sethrefValue(val)

  };
  const handleEmail = (e) => {
    setEmailValue(e.target.value);
  };
  const handleName = (e) => {
    setNameValue(e.target.value);
  };
  const handleCompanyName = (e) => {
    setCompanyNameValue(e.target.value);
  };
  const handleMobile = (e) => {
    setMobileValue(e.target.value);
  };
  useEffect(() => {
    localStorage.getItem("savedItems") &&
      JSON.parse(localStorage.getItem("savedItems")).some(
        (element) => element.displayName === localStorage.getItem("ToolName")
      ) &&
      setSaveDisabled(true);
  });
  useEffect(() => {
    localStorage.getItem("compareItems") &&
      (JSON.parse(localStorage.getItem("compareItems")).some(
        (element) =>
          element === JSON.parse(localStorage.getItem("ToolMain")).code
      ) ||
        JSON.parse(localStorage.getItem("compareItems")).length > 6) &&
      setCompareDisabled(true);
  });
  useEffect(() => {
    // console.log(Category);
    setStep(JSON.parse(localStorage.getItem("Step")));
    Tool ?
      setData(Tool) :
      setData(JSON.parse(localStorage.getItem("ToolMain")))
    // axios
    //   .get(
    //     `https://sphoortitoolscatalogapi20211217232946.azurewebsites.net/tools/api/v1/catalogs/tool-models/${Type ? Type.code : localStorage.getItem("TypeCode")
    //     }/tool-categories/${Category ? Category.code : localStorage.getItem("CategoryCode")
    //     }`
    //   )
    //   .then((res) => {
    //     // console.log(JSON.parse(res.data.data[0].feature.value));
    //     setData(res.data.data);
    //     console.log(res.data.data);
    //     setLoading(false);
    //   });
    // console.log(Data);
  }, []);
  // useEffect(() => {
  //   setNewData(
  //     Data.filter(
  //       (el) =>
  //         el.displayName !==
  //         (Tool ? Tool.displayName : localStorage.getItem("ToolName"))
  //     )
  //   );
  // }, [Data, Tool]);
  // console.log("here", JSON.parse(localStorage.getItem("ToolFeature")));
  const [ActiveSection, setActiveSection] = useState(1);
  const handleSelectTool = (tool) => {
    setStep(5);
    navigate(`/accessories/tool-details`);
    setTool(tool);
    localStorage.setItem("ToolName", tool.displayName);
    localStorage.setItem("AcessoriesFeatures", tool.features);
    localStorage.setItem("ToolMachine", JSON.stringify(tool.machineReferences));
    localStorage.setItem("ToolImage", tool.images.bigImageURL);
    localStorage.setItem("ToolDocs", JSON.stringify(tool.docs));
    localStorage.setItem("ToolMain", JSON.stringify(tool));
    // localStorage.setItem("CategoryName", element.name);
    localStorage.setItem("Step", 5);
    console.log(tool);
    // setActiveSection(1);
    window.location.reload();

    // setToolDetail(toolDetail);
  };

  const handleCompareClickWorking = () => {
    localStorage.setItem(
      "compareItems",
      JSON.stringify(
        localStorage.getItem("compareItems")
          ? [
            ...JSON.parse(localStorage.getItem("compareItems")),
            JSON.parse(localStorage.getItem("ToolMain")).code,
          ]
          : [JSON.parse(localStorage.getItem("ToolMain")).code]
      )
    );
    window.location.reload();
  };
  return (
    <>
      <div className={`${styles.HomePage} ${styles.MachineToolsFifth}`}>
        <div className={styles.PageFlowWrapper}>
          <div className={styles.TopDiv}>
            <h2>
              <b>Search Tool Holder Acessories</b>
            </h2>
          </div>
          <PageFlowMachineTool
            Step={Step}
            setStep={setStep}
            Manu={Manu}
            Type={Type}
            Spec={Spec}
            Category={Category}
            Tool={Tool}
          />
        </div>
        <div className={styles.PageContentWrapper}>
          <div className={styles.TopDiv}>
            <h2>
              <b>Tool holder details</b>
            </h2>
            {/* <p>
            Once you have located the tool you are looking for, you can add it
            to the shopping cart by clicking the black shopping cart symbol. It
            is sufficient to click the white shopping cart in the navigation to
            display your current shopping cart. Click the miniature view of the
            drawing to obtain a preview of the drawing in the browser. The
            preview opens in a new window or a new tab.
          </p> */}
          </div>
          <div
            className={styles.ToolDetailsContainer}
            style={{ marginTop: "50px" }}
          >
            <div className={styles.ToolDetailsHeading}>
              <h2>
                {Category ? Category.name : localStorage.getItem("CategoryName")}
              </h2>
              <div>
                <h1>
                  {Tool ? Tool.articleId: localStorage.getItem("ToolName")}
                </h1>
                <span className={styles.ToolHeadingMobileSpan}>
                <Tippy content="Add to Comparelist">
                  <button
                    disabled={CompareDisabled}
                    onClick={() =>
                      localStorage.getItem("compareItems")
                        ? JSON.parse(localStorage.getItem("compareItems"))
                          .length > 5
                          ? alert("reached maximum compare")
                          : handleCompareClickWorking()
                        : handleCompareClickWorking()
                    }
                  >
                    <FontAwesomeIcon icon={faCopy} /> Compare
                  </button>
                  </Tippy>
                  <Tippy content="Add to Watchlist">
                  <button
                    disabled={SaveDisabled}
                    onClick={() => {
                      localStorage.setItem(
                        "savedItems",
                        JSON.stringify(
                          localStorage.getItem("savedItems")
                            ? [
                              ...JSON.parse(localStorage.getItem("savedItems")),
                              JSON.parse(localStorage.getItem("ToolMain")),
                            ]
                            : [
                              JSON.parse(
                                //   localStorage.getItem("savedItems")
                                //     ? JSON.parse(
                                //         localStorage.getItem("savedItems")
                                //       ).push(
                                //         JSON.parse(localStorage.getItem("ToolMain"))
                                //       )
                                localStorage.getItem("ToolMain")
                              ),
                            ]
                        )
                      );
                      window.location.reload();
                    }}
                  >
                    <FontAwesomeIcon icon={faSave} /> Save
                  </button>
                  </Tippy>
                </span>
              </div>
              <span className={styles.machineToolsButtonContainer}>
                <ul>
                  <li className={styles.article_font}>
                    Article Id :{" "}
                    {Tool
                      ? Tool.articleId
                      : JSON.parse(localStorage.getItem("ToolMain")).articleId}
                  </li>
                  {/* <li>External coolant supply</li>
                <li>180° reversable</li> */}
                </ul>
                <img
                  alt="tool"
                  src={`${
                    Tool
                      ? Tool.images.bigImageURL
                      : localStorage.getItem("ToolImage")
                  }`}
                  style={{ width: "35%" }}
                ></img>
              </span>
            </div>
            <div className={styles.ToggleButtonArray}>
              <button
                style={{
                  background: ActiveSection === 1 && "#f1f1f1",
                  color: ActiveSection === 1 && "#67a92c",
                }}
                onClick={() => setActiveSection(1)}
              >
                Features
              </button>
              {/* <button
                style={{
                  background: ActiveSection === 2 && "#f1f1f1",
                  color: ActiveSection === 2 && "#67a92c",
                }}
                onClick={() => setActiveSection(2)}
              >
                Machine references
              </button> */}
              {/* <button
                style={{
                  background: ActiveSection === 3 && "#f1f1f1",
                  color: ActiveSection === 3 && "#67a92c",
                }}
                onClick={() => setActiveSection(3)}
              >
                Variants
              </button> */}
              <button
                style={{
                  background: ActiveSection === 4 && "#f1f1f1",
                  color: ActiveSection === 4 && "#67a92c",
                }}
                onClick={() => setActiveSection(4)}
              >
                drawings
              </button>
            </div>
            {ActiveSection === 1 && (
              <div className={styles.ToolDetails}>
                <ul>
                  {JSON.parse(
                    Tool
                      ? Tool.features
                      : localStorage.getItem("AcessoriesFeatures")
                  ).map((element) => {
                    return (
                      <li style={{ width: "100%" }}>
                        <span>{`${element.identifier || element.IDENTIFIER}: ${element.value || element.VALUE
                          }`}</span>
                      </li>
                    );
                  })}
                </ul>
              </div>
            )}
            {ActiveSection === 2 && (
              <div
                className={styles.ToolDetails}
                style={{ flexDirection: "column" }}
              >
                {/* <h2>Compatible Machines</h2> */}
                <ul>
                  {(Tool
                    ? Tool.machineReferences
                    : JSON.parse(localStorage.getItem("ToolMachine"))
                  ).map((element) => {
                    return (
                      <li style={{ width: "100%" }}>
                        <span>{`${element.features}`}</span>
                      </li>
                    );
                  })}
                </ul>
              </div>
            )}
            {ActiveSection === 3 && (
              <div
                className={styles.ToolDetails}
                style={{ flexDirection: "column" }}
              >
                <h2 style={{ marginBottom: "50px" }}>Variants</h2>
                {NewData.map((element) => {
                  return (
                    <div
                      className={styles.ToolDataContainer}
                      style={{ width: "95%" }}
                      onClick={() => handleSelectTool(element)}
                    >
                      <h1
                        className={styles.ToolDataTag}
                        id={`${element.displayName}`}
                      >
                        {element.displayName}
                      </h1>
                      <div className={styles.ToolHeading}>
                        <h1>{element.articleId}</h1>
                        {/* <button>
                  <FontAwesomeIcon icon={faCopy} /> Add to comparison
                </button> */}
                      </div>
                      <div
                        className={styles.ToolDetails}
                        style={{ background: "#e3e3e3" }}
                      >
                        <div>
                          <h2>Tool data</h2>
                          <p>{element.description}</p>
                        </div>
                        <img
                          alt="product"
                          width="20%"
                          // src="https://ik.imagekit.io/syeovulacjk/sptools/asset/images/10004.jpg"
                         // src={element.images.bigImageURL}
                        />
                        {/* <ul>
                  <li>
                    <span>X: 0</span>
                  </li>
                  <li>
                    <span>Mnr: S03513</span>
                  </li>
                </ul> */}
                      </div>
                    </div>
                  );
                })}
              </div>
            )}

            {ActiveSection === 4 && (
              <div
                className={styles.ToolDetails}
                style={{ flexDirection: "column" }}
              >
                <h2>Downloads</h2>
                <div className={styles.DownloadsInfoContainer}>
                  <div
                    className={styles.DownloadsSection}
                    style={{ borderRight: "2px solid #ccc" }}
                  >
                    <h4>Drawings</h4>
                    <div>
                      <div onClick={() => {
                        handleShow(Tool
                          ? Tool.docs.pdfFileURL
                          : JSON.parse(localStorage.getItem("ToolDocs"))
                            .pdfFileURL)
                      }}
                      >
                        <img
                          alt="pdf"
                          src="https://ewswebstorageprod.blob.core.windows.net/media/Default/images/PdfIcon.png"
                        />
                      </div>
                      <div onClick={() => {
                        handleShow(Tool
                          ? Tool.docs.dxfFileURL
                          : JSON.parse(localStorage.getItem("ToolDocs"))
                            .dxfFileURL)
                      }
                      }
                        target="_blank"
                      >
                        <img
                          alt="dxf"
                          src="https://ewswebstorageprod.blob.core.windows.net/media/Default/images/DxfIcon.png"
                        />
                      </div>
                    </div>
                  </div>
                  <div className={styles.DownloadsSection}>
                    <h4>3D Model</h4>
                    <div>
                      <div onClick={() => {
                        handleShow(Tool

                          ? Tool.docs.stpFileURL
                          : JSON.parse(localStorage.getItem("ToolDocs"))
                            .stpFileURL)
                      }
                      }
                        target="_blank"
                      >
                        <img
                          alt="stp"
                          src="https://ewswebstorageprod.blob.core.windows.net/media/Default/images/StpIcon.png"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        {show && (
          <>
            <div className={`${styles.PopUpForm} `}>
              <div className={styles.popForm}>
                <span> * Enter All the fields</span>
                <form>
                  <div className={styles.PopUpFormField}>
                    <label>Name</label>
                    <input
                      type="text"
                      placeholder="Enter Name"
                      onChange={(e) => handleName(e)}
                      required
                    />
                  </div>
                  <div className={styles.PopUpFormField}>
                    <label>Email</label>
                    <input
                      type="email"
                      placeholder="Enter email"
                      onChange={(e) => handleEmail(e)}
                      required
                    />
                  </div>
                  <div className={styles.PopUpFormField}>
                    <label>Company Name</label>
                    <input
                      type="text"
                      placeholder="Enter Company Name"
                      onChange={(e) => handleCompanyName(e)}
                      required
                    />
                  </div>
                  <div className={styles.PopUpFormField}>
                    <label>Mobile No.</label>
                    <input
                      type="number"
                      placeholder="Enter Mobile Number"
                      onChange={(e) => handleMobile(e)}
                      required
                    />
                  </div>
                  <div className={styles.popUpButton}>
                    <button
                      disabled={
                        NameValue.length < 3 ||
                        EmailValue.length < 5 ||
                        CompanyNameValue.length < 3 ||
                        MobileValue.length < 10
                      }
                      type="submit"
                      onClick={() => handleSubmit()}
                    >
                      Get the File
                    </button>
                    <button onClick={() => handleClose()}>Close </button>
                  </div>
                </form>
              </div>
            </div>
          </>
        )}
      </div>
      {/* <Modal show={show} onHide={()=>handleClose()}>
      <Modal.Header closeButton>
        <Modal.Title>Modal heading</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Name"
              onChange={(e) => handleName(e)}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Email address</Form.Label>
            <Form.Control
              type="email"
              placeholder="Enter email"
              onChange={(e) => handleEmail(e)}
            />
            <Form.Text className="text-muted">
              We'll never share your email with anyone else.
            </Form.Text>
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Company Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Company Name"
              onChange={(e) => handleCompanyName(e)}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Mobile No.</Form.Label>
            <Form.Control
              type="number"
              placeholder="Enter Mobile Number"
              onChange={(e) => handleMobile(e)}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={()=>handleClose()}>
          Close
        </Button>
        <Button
          variant="primary"
          onClick={()=>handleSubmit()}
          disabled={
            NameValue.length < 3 ||
            EmailValue.length < 5 ||
            CompanyNameValue.length < 3 ||
            MobileValue.length < 10
          }
        >
          Download Catalogue
        </Button>
      </Modal.Footer>
    </Modal> */}
    </>
  );
}

export default ToolDetails;