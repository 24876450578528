import React from "react";
import styles from "../../styles/SelectManufacturer.module.css";
import { useNavigate } from "react-router-dom";
import PageFlowMachineTool from "./PageFlowMachineTool";

function ToolSpec({ Step, setStep, Manu, Type, setSpec }) {
  let navigate = useNavigate();
  const handleSelectSpec = (element) => {
    setStep(4);
    navigate(`/machine-tool/select-category`);
    setSpec(element);
  };
  // const alphabetArray = "abcdefghijklmnopqrstuvwxyz".split("");
  // const Arr = ["Static tools", "Driven tools"];
  return (
    <div className={styles.HomePage}>
      <div className={styles.TopDiv}>
        <h2>
          <b>Search Machine Tool Holder</b>
        </h2>
      </div>
      <PageFlowMachineTool
        Step={Step}
        setStep={setStep}
        Manu={Manu}
        Type={Type}
      />
      <div className={styles.TopDiv}>
        <h2>
          <b>Select specification</b>
        </h2>
        <p>
          Select the required machine specification to display the matching tool
          categories.
        </p>
      </div>
      <div className={styles.SpecDictionaryContainer}>
        <div className={styles.SpecContainer}>
          <div className={styles.SpecHeading}>
            <h1>VDI Disc turret</h1>
          </div>
          <div className={styles.SpecListContainer}>
            <ul>
              <li onClick={() => handleSelectSpec("VDI Disc turret - Static")}>
                <span>Static tools</span>
              </li>
              <li onClick={() => handleSelectSpec("VDI Disc turret - Driven")}>
                <span>Driven tools</span>
              </li>
            </ul>
          </div>
        </div>
        <div className={styles.ImageContainer}>
          <img
            alt="spec"
            src="https://img.directindustry.com/images_di/photo-g/35541-2833205.jpg"
          />
        </div>
      </div>
    </div>
  );
}

export default ToolSpec;
