import React from "react";
import { useNavigate } from "react-router-dom";
import styles from "../styles/HomePage.module.css";

function Card({ CardHead, CardIMG, CardInfo, route }) {
  let navigate = useNavigate();
  const handleClick = () => {
    navigate(`/${route}`);
  };
  return (
    <div  className={styles.CardContainer} >
      <div onClick={handleClick} className={styles.Card}>
        <img src={CardIMG} width="85%" height="auto" alt="EWS article number" />
        <div className={styles.CardHead}>
          <b>{CardHead}</b>
        </div>
        {/* <p className={styles.CardInfo}>{CardInfo}</p> */}
      </div>
    </div>
  );
}

export default Card;
