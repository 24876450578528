import { faCopy, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react/cjs/react.development";
import styles from "../../styles/WatchList.module.css";

function WatchList({ setTool }) {
  let navigate = useNavigate();
  const handleBack = () => {
    navigate("/");
  };
  const handleSelectTool = (tool) => {
    // setStep(5);
    navigate(`/article-number/tool-details`);
    setTool(tool);
    localStorage.setItem("ToolName", tool.displayName);
    localStorage.setItem("ToolFeature", tool.feature.value);
    localStorage.setItem("ToolMachine", JSON.stringify(tool.machineReferences));
    localStorage.setItem("ToolImage", tool.images.bigImageURL);
    localStorage.setItem("ToolDocs", JSON.stringify(tool.docs));
    localStorage.setItem("ToolMain", JSON.stringify(tool));
    localStorage.setItem("Article", tool.articleId);
    // localStorage.setItem("CategoryName", element.name);
    localStorage.setItem("Step", 3);
    console.log(tool);
    window.location.reload();
    // setToolDetail(toolDetail);
  };
  const Saved = JSON.parse(localStorage.getItem("savedItems"));
  return (
    <div className={`${styles.WatchList} ${styles.WatchListMobile}`}>
      <div className={styles.TopDiv}>
        <h2>Watch List</h2>
      </div>
      <div className={styles.MainContainer}>
        <div className={styles.MainHeader}>
          <button onClick={() => handleBack()}>&lt; Back</button>
          <h3>PRODUCTS</h3>
          <button
            onClick={() => {
              localStorage.removeItem("savedItems");
              window.location.reload();
            }}
          >
            Clear All
          </button>
        </div>
        {Saved ? (
          Saved.map((element) => {
            return (
              <div className={styles.ToolDataContainer}>
                <h1
                  className={styles.ToolDataTag}
                  id={`${element.displayName}`}
                >
                  {element.displayName}
                </h1>
                <div className={styles.ToolHeading}>
                  <h1>{element.articleId}</h1>
                  <div>
                    {/* <button
                      style={{ marginRight: "10px" }}
                      onClick={() => {
                        localStorage.setItem(
                          "compareItems",
                          JSON.stringify(
                            localStorage.getItem("compareItems")
                              ? [
                                  ...JSON.parse(
                                    localStorage.getItem("compareItems")
                                  ),
                                  JSON.parse(element),
                                ]
                              : [
                                  JSON.parse(
                                    //   localStorage.getItem("savedItems")
                                    //     ? JSON.parse(
                                    //         localStorage.getItem("savedItems")
                                    //       ).push(
                                    //         JSON.parse(localStorage.getItem("ToolMain"))
                                    //       )
                                    element
                                  ),
                                ]
                          )
                        );
                        window.location.reload();
                      }}
                    >
                      <FontAwesomeIcon icon={faCopy} /> Compare
                    </button> */}
                    <button
                      onClick={() => {
                        JSON.parse(localStorage.getItem("savedItems")).length >
                        1
                          ? localStorage.setItem(
                              "savedItems",
                              JSON.stringify(
                                JSON.parse(
                                  localStorage.getItem("savedItems")
                                ).filter(
                                  (el) => el.displayName !== element.displayName
                                )
                              )
                            )
                          : localStorage.removeItem("savedItems");
                        window.location.reload();
                      }}
                    >
                      <FontAwesomeIcon icon={faTrashAlt} /> Remove
                    </button>
                  </div>
                </div>
                <div
                  className={styles.ToolDetails}
                  onClick={() => handleSelectTool(element)}
                >
                  <div>
                    <h2>Tool data</h2>
                    <p>{element.description}</p>
                  </div>
                  <img
                    alt="product"
                    style={{ width: "35%" }}
                    src={`${
                      element
                        ? element.images.bigImageURL
                        : localStorage.getItem("ToolImage")
                    }`}
                  />
                </div>
              </div>
            );
          })
        ) : (
          <div className={styles.ToolDataContainer} style={{ padding: "40px" }}>
            <h1>no results</h1>
          </div>
        )}
      </div>
    </div>
  );
}

export default WatchList;
