import React, { useEffect, useState } from "react";
import styles from "../../styles/SelectManufacturer.module.css";
import { useNavigate } from "react-router-dom";
import PageFlowMachineTool from "./PageFlowMachineTool";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { faHandPointRight } from "@fortawesome/free-solid-svg-icons";
import { faFilter } from "@fortawesome/free-solid-svg-icons";

function SelectCategory({ Step, setStep, Manu, Type, Spec, setCategory }) {
  let navigate = useNavigate();
  const handleSelectCategory = (element) => {
    setStep(4);
    navigate(`/turrets/select-tool`);
    setCategory(element);
    localStorage.setItem("CategoryCode", element.code);
    localStorage.setItem("CategoryName", element.name);
    localStorage.setItem("Step", 4);
    localStorage.removeItem('selectCatFilters')
  
  };
  const alphabetArray = "abcdefghijklmnopqrstuvwxyz".split("");
  const [Data, setData] = useState([]);
  const [Loading, setLoading] = useState(true);
  const [Filters, setFilters] = useState(false);
  const [PostData, setPostData] = useState({
    modelCode: `${Type ? Type.code : localStorage.getItem("TypeCode")}`,
    filters: [],
  });
  const postThis = localStorage.getItem("selectCatFilters")
    ? JSON.parse(localStorage.getItem("selectCatFilters")).modelCode ===
      localStorage.getItem("TypeCode")
      ? JSON.parse(localStorage.getItem("selectCatFilters"))
      : PostData
    : PostData;
  const handleFilter = (filter, category) => {
    const isSameElement = (element) => element.category === category;
    const isStringElement = (element) => element.category === "string";
    const isSameFilter = (element) => element === filter;
    setLoading(true);
    setData(null);
    postThis.filters.some((element) => element.category === "string") &&
      postThis.filters.splice(postThis.filters.findIndex(isStringElement), 1);
    const object = {
      category: `${category}`,
      filters: [`${filter}`],
    };
    postThis.filters.some((element) => element.category === category)
      ? postThis.filters[
          postThis.filters.findIndex(isSameElement)
        ].filters.some((element) => element === filter)
        ? postThis.filters[
            postThis.filters.findIndex(isSameElement)
          ].filters.splice(
            postThis.filters[
              postThis.filters.findIndex(isSameElement)
            ].filters.findIndex(isSameFilter),
            1
          )
        : postThis.filters[
            postThis.filters.findIndex(isSameElement)
          ].filters.push(filter)
      : postThis.filters.push(object);

    for (let i = 0; i < postThis.filters.length; i++) {
      if (postThis.filters[i].filters.length === 0) {
        postThis.filters.splice(i, 1);
      }
    }

    setPostData(postThis);
    localStorage.setItem("selectCatFilters", JSON.stringify(postThis));
    window.location.reload();
  };
 

  useEffect(() => {
    setStep(JSON.parse(localStorage.getItem("Step")));
    axios
    .post(
      "https://sphoortitoolscatalogapi20211217232946.azurewebsites.net/tools/api/v1/catalogs/tool-models/tool-categories-filters",
      localStorage.getItem("selectCatFilters") ? JSON.parse(localStorage.getItem("selectCatFilters")):PostData
    )
    .then((res) => {
      setData(res.data.data.toolCategoryVMs);
      console.log(res)
      setFilters(res.data.data.productFilters)
      setLoading(false);
    }).catch((err)=>{
      alert(err);
      localStorage.removeItem('selectCatFilters')
      setLoading(false);
      window.location.reload();
    });

  }, []);
  // const Arr = ["Static tools", "Driven tools"];
  return (
    <div className={`${styles.HomePage} ${styles.MachineToolsThird}`}>
      <div className={styles.PageFlowWrapper}>
        <div className={styles.TopDiv}>
          <h2>
            <b>Search Machine Tool Holder by Clicking</b>
          </h2>
        </div>
        <PageFlowMachineTool
          Step={Step}
          setStep={setStep}
          Manu={Manu}
          Type={Type}
          Spec={Spec}
        />
      </div>
      <div className={styles.PageContentWrapper}>
        <div className={styles.TopDiv}>
          <h2>
            <b>Select Product Family by Clicking</b>
          </h2>
          {/* <p>
            Select from the following categories to obtain a list with EWS tools
            for your machine.
          </p> */}
        </div>
        {/* <div className={styles.ToolDictionaryContainer} style={{ marginBottom: "10px" }}>
          <ul className={styles.ToolListContainer}>
            {Loading && <p>Loading...</p>}
            {!Loading && alphabetArray.map((alpha) => {
              return (
                <>
                  {Data.some((element) =>
                    element.name.startsWith(alpha.toUpperCase())
                  ) &&
                    (
                      <li style={{ width: "4%" }}>
                        <a href={`#${alpha}`}>{alpha.toUpperCase()}</a>
                      </li>
                    )
                  }
                </>
              );
            })}
          </ul>
        </div> */}
        <div
          className={styles.DictionaryContainer}
          style={{ marginTop: "55px" }}
        >
          {Loading && <p>Loading...</p> } 
          {Data===undefined &&  <p>No content available</p> }
          {/* {console.log(Loading, Data)} */}
          { alphabetArray.map((alpha, i) => {
            return (
              <>
                {Data && 
                  Data.some((element) =>
                    element.name.startsWith(alpha.toUpperCase())
                  ) && (
                    <div
                      className={styles.AlphaContainer}
                      key={i}
                      id={`${alpha}`}
                    >
                      <FontAwesomeIcon
                        style={{ color: "#275207" }}
                        icon={faHandPointRight}
                        className={styles.ElementIcon}
                      />
                      <b style={{ visibility: "hidden" }}>
                        {alpha.toUpperCase()}
                      </b>
                      <ul className={styles.onListHover}>
                        {Data.map((element, index) => {
                          return (
                            element.name.startsWith(alpha.toUpperCase()) && (
                              <li key={index}>
                                {
                                  <div
                                    onClick={() =>
                                      handleSelectCategory(element)
                                    }
                                    // style={{
                                    //   top: "-20%",
                                    //   marginBottom: "-35px",
                                    // }}
                                  >
                                    <img
                                      alt="product"
                                      src={element.imageURL}
                                      style={{
                                        maxHeight: "fit-content",
                                        width: "50%",
                                   
                                      }}
                                    ></img>
                                    <span key={index}>{element.name}</span>
                                  </div>
                                }
                              </li>
                            )
                          );
                        })}
                      </ul>
                    </div>
                  )}
              </>
            );
          })}
        </div>
        {/* <div className={styles.ToolDictionaryContainer} style={{ marginBottom: "10px" }}>
          <ul className={styles.ToolListContainer}>
            {Loading && <p>Loading...</p>}
            {!Loading && alphabetArray.map((alpha) => {
              return (
                <>
                  {Data.some((element) =>
                    element.name.startsWith(alpha.toUpperCase())
                  ) &&
                    (
                      <li style={{ width: "4%" }}>
                        <a href={`#${alpha}`}>{alpha.toUpperCase()}</a>
                      </li>
                    )
                  }
                </>
              );
            })}
          </ul>
        </div> */}
      </div>
      <div className={styles.FilterWrapper}>
        <div className={styles.TopDiv} style={{ display: "flex" }}>
          <h2>
            <b>Filter By Parameter</b>
          </h2>
          <FontAwesomeIcon
            style={{ color: "#275207" }}
            icon={faFilter}
            className={styles.ElementIcon}
          />
          {/* <img src="https://ik.imagekit.io/0rsvz9vomoe/catalog_home/filter-icon-25207_u7eEsrP4n.png?ik-sdk-version=javascript-1.4.3&updatedAt=1645774007729" style={{width:"50px" ,height:"50px",marginTop:"10px"}}></img> */}
        </div>
        <div
          className={styles.SpecDictionaryContainer}
        >
          <div className={styles.SpecContainer}>
            {Filters &&
              Filters.map((element, i) => {
                return (
                  <>
                    <div
                      className={styles.SpecHeading}
                      style={{ fontSize: "8px" }}
                    >
                      <h1>{element.category}</h1>
                    </div>
                    <div
                      className={styles.FilterListContainer}
                      style={{
                        borderBottom: i === Filters.length - 1 ? "none" : "",
                      }}
                    >
                      {element.filters.map((filt) => {
                        return (
                          <div>
                            <input
                              type="checkbox"
                              onClick={() =>
                                handleFilter(filt, element.category)
                              }
                              defaultChecked={
                                localStorage.getItem("selectCatFilters") &&
                                JSON.parse(
                                  localStorage.getItem("selectCatFilters")
                                ).modelCode ===
                                  localStorage.getItem("TypeCode") &&
                                JSON.parse(
                                  localStorage.getItem("selectCatFilters")
                                ).filters.some(
                                  (el) => el.category === element.category
                                )
                                  ? JSON.parse(
                                      localStorage.getItem("selectCatFilters")
                                    ).filters[
                                      JSON.parse(
                                        localStorage.getItem("selectCatFilters")
                                      ).filters.findIndex(
                                        (el) => el.category === element.category
                                      )
                                    ].filters.some((el) => el === filt)
                                  : false
                              }
                            ></input>
                            <label>{filt}</label>
                          </div>
                        );
                      })}
                    </div>
                  </>
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default SelectCategory;
