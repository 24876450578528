import React, { useEffect, useState } from "react";
import styles from "../../styles/SelectManufacturer.module.css";
import { useNavigate } from "react-router-dom";
import PageFlowMachineTool from "./PageFlowMachineTool";
import axios from "axios";
import { faHandPointRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function MachineType({ Step, setStep, Manu, setManu, setType }) {
  let navigate = useNavigate();
  const handleSelectType = (element) => {
    setStep(3);
    navigate(`/accessories/select-category`);
    setType(element);
    localStorage.setItem("TypeCode", element.code);
    localStorage.setItem("TypeName", element.name);
    localStorage.setItem("Step", 3);
  };
  const alphabetArray = "abcdefghijklmnopqrstuvwxyz".split("");
  const [Data, setData] = useState([]);
  const [Loading, setLoading] = useState(false);
  // useEffect(() => {
  //   setStep(JSON.parse(localStorage.getItem("Step")));
  //   axios
  //     .get(
  //       `https://sphoortitoolscatalogapi20211217232946.azurewebsites.net/tools/api/v1/catalogs/accessory-categories/${
  //         Manu ? Manu.code : localStorage.getItem("ManuCode")
  //       }/accessory-families`
  //     )
  //     .then((res) => {
  //       setData(res.data.data);
  //       setLoading(false);
  //       console.log(Manu);
  //       console.log(localStorage.getItem("ManuCode"));
  //     });
  //   // console.log(Data);
  // }, []);
  useEffect(() => {
    Manu ?
      setData(Manu.subCategories) :
      setData(JSON.parse(localStorage.getItem('accessory')).subCategories)

    localStorage.getItem('Step') && setStep(JSON.parse(localStorage.getItem("Step")))
  })
  const Arr = ["UT200", "UT300"];
  return (
    <div className={`${styles.HomePage} ${styles.MachineToolSecond}`}>
      <div className={styles.PageFlowWrapper}>
        <div className={styles.TopDiv}>
          <h2>
            <b>Search Tool Holder Acessories</b>
          </h2>
        </div>
        <PageFlowMachineTool Step={Step} setStep={setStep} Manu={Manu} />
      </div>
      <div className={styles.PageContentWrapper}>
        <div className={styles.TopDiv}>
          <h2>
            <b>Select machine model by Clicking</b>
          </h2>
          {/* <p>
            Once you have decided on a model from the list, click it to select
            the specification next. Press the navigation arrow on the left in
            the upper bar to go one step back.
          </p> */}
        </div>
        {/* <div className={styles.ToolDictionaryContainer} style={{ marginBottom: "10px" }}>
          <ul className={styles.ToolListContainer}>
            {Loading && <p>Loading...</p>}
            {!Loading && alphabetArray.map((alpha) => {
              return (
                <>
                  {Data.some((element) =>
                    element.name.startsWith(alpha.toUpperCase())
                  ) &&
                    (
                      <li style={{ width: "4%" }}>
                        <a href={`#${alpha}`}>{alpha.toUpperCase()}</a>
                      </li>
                    )
                  }
                </>
              );
            })}
          </ul>
        </div> */}
        <div
          className={styles.DictionaryContainer}
          style={{ marginTop: "50px" }}
        >
          <img alt="manufacturer" src="https://ik.imagekit.io/syeovulacjk/sptools/asset/company/logo/sphoorti.png" />
          {Loading && <p>Loading...</p>}
          {alphabetArray.map((alpha, i) => {
            return (
              <>
                {Data &&
                  Data.some((element) =>
                    element.name.startsWith(alpha.toUpperCase())
                  ) && (
                    <div
                      className={styles.AlphaContainer}
                      key={i}
                      id={`${alpha}`}
                    >
                      <FontAwesomeIcon
                        style={{ color: "#275207" }}
                        icon={faHandPointRight}
                        className={styles.ElementIcon}
                      />
                      <b style={{ visibility: "hidden" }}>
                        {alpha.toUpperCase()}
                      </b>
                      <ul className={styles.onListHover}>
                        {Data.map((element, index) => {
                          return (
                            element.name.startsWith(alpha.toUpperCase()) && (
                              <li key={index}>
                                {
                                  <span
                                    onClick={() => handleSelectType(element)}
                                    key={index}
                                  >
                                    {element.name}
                                  </span>
                                }
                              </li>
                            )
                          );
                        })}
                      </ul>
                    </div>
                  )}
              </>
            );
          })}
        </div>
        {/* <div className={styles.ToolDictionaryContainer} style={{ marginBottom: "10px" }}>
          <ul className={styles.ToolListContainer}>
            {Loading && <p>Loading...</p>}
            {!Loading && alphabetArray.map((alpha) => {
              return (
                <>
                  {Data.some((element) =>
                    element.name.startsWith(alpha.toUpperCase())
                  ) &&
                    (
                      <li style={{ width: "4%" }}>
                        <a href={`#${alpha}`}>{alpha.toUpperCase()}</a>
                      </li>
                    )
                  }
                </>
              );
            })}
          </ul>
        </div> */}
      </div>
    </div>
  );
}

export default MachineType;
