import {
  faCopy,
  faHandPointRight,
  faQuestion,
  faSave,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useNavigate } from "react-router-dom";
import styles from "../styles/Navbar.module.css";

function Navbar() {
  let navigate = useNavigate();
  const handleClickList = (props) => {
    navigate(`/${props}-list`);
    closeNav();
  };

  function openNav() {
    document.getElementById("mySidenav").style.width = "100%";
  }

  function closeNav() {
    document.getElementById("mySidenav").style.width = "0";
  }

  return (
    <>
      {window.innerWidth >= 992 ? (
        <>
          <div className={styles.Navbar}>
            <div className={styles.LeftSide}>
              {/* <a href="https://sphoorti-new.web.app/">
          <button>&lt; EXIT CATALOG</button>
        </a> */}
              <a href="https://sphoorti-tools.com/">
                <img
                  width="180px"
                  alt="sphoorti-logo"
                  src="https://ik.imagekit.io/syeovulacjk/sptools/asset/company/logo/Sphoorti.png"
                  style={{ marginTop: "5px" }}
                ></img>
              </a>
            </div>

            <div className={styles.LeftSide}>
              <a
                href={
                  window.location.href == "https://sphoorti-tools.com/"
                    ? "https://www.sphoorti.com/"
                    : "https://sphoorti-tools.com/"
                }
              >
                <button className={styles.ExitCatalog}>
                  <FontAwesomeIcon
                    icon={faHandPointRight}
                    className={styles.ElementIcon}
                  />
                  {window.location.href == "https://sphoorti-tools.com/"
                    ? "Go Back to Main Website"
                    : `Go Back to Catalogue`}
                </button>
              </a>
              {console.log(window.location.href, "text qw")}
            </div>
            <div className={styles.RightSide}>
              <button onClick={() => handleClickList("compare")}>
                <FontAwesomeIcon icon={faCopy} />
                &nbsp; Compare List
                <div>
                  {localStorage.getItem("compareItems")
                    ? JSON.parse(localStorage.getItem("compareItems")).length
                    : 0}
                </div>
              </button>
              <button onClick={() => handleClickList("watch")}>
                <FontAwesomeIcon icon={faSave} />
                &nbsp; Watch List
                <div>
                  {localStorage.getItem("savedItems")
                    ? JSON.parse(localStorage.getItem("savedItems")).length
                    : 0}
                </div>
              </button>
            </div>
          </div>
        </>
      ) : (
        <>
          {/* navbar  */}

          <div className={styles.MobileNavbar}>
            <div className={styles.floatChild}>
              <a href="https://sphoorti-tools.com/">
                <img
                  alt="sphoorti-logo"
                  src="https://ik.imagekit.io/syeovulacjk/sptools/asset/company/logo/Sphoorti.png"
                  style={{ width: "100%", marginTop: "5px" }}
                />
              </a>
            </div>
            <div className={`${styles.floatChild} ${styles.floatChildSecond}`}>
              <span
                style={{
                  fontSize: "20px",
                  cursor: "pointer",
                }}
                onClick={openNav}
              >
                &#9776; Menu
              </span>
              {/* //menu box
              -----------
            ------------*/}

              <div id="mySidenav" className={styles.sidenav}>
                <img
                  alt="sphoorti-logo"
                  src="https://ik.imagekit.io/syeovulacjk/sptools/asset/company/logo/Sphoorti.png"
                  style={{ width: "30%", marginTop: "5px" }}
                ></img>
                <div></div>
                <br />
                <div className={styles.LeftSide} style={{ marginLeft: "-6%" }}>
                  <a href="https://sphoorti.com/">
                    <button className={styles.ExitCatalog}>
                      <FontAwesomeIcon
                        icon={faHandPointRight}
                        className={styles.ElementIcon}
                      />
                      Go Back to Main Website
                    </button>
                  </a>
                </div>
                <br />
                <a
                  href="javascript:void(0)"
                  className={styles.closebtn}
                  onClick={closeNav}
                >
                  &times;
                </a>
                <div className={styles.RightSide}>
                  <button onClick={() => handleClickList("compare")}>
                    <FontAwesomeIcon icon={faCopy} />
                    &nbsp; Compare List
                    <div>
                      {localStorage.getItem("compareItems")
                        ? JSON.parse(localStorage.getItem("compareItems"))
                            .length
                        : 0}
                    </div>
                  </button>
                  <button onClick={() => handleClickList("watch")}>
                    <FontAwesomeIcon icon={faSave} />
                    &nbsp; Watch List
                    <div>
                      {localStorage.getItem("savedItems")
                        ? JSON.parse(localStorage.getItem("savedItems")).length
                        : 0}
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default Navbar;
